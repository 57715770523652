<template>
     <!-- Copyright -sec-section -->
     <div class="copyright-sec">
         <div class="container-fluid">
            <div class="copyright-text">
               <p>&copy;Copyright 2010 - 2023 {{ domainName }}</p>
            </div>
         </div>
      </div>
</template>

<script>
export default{
    name:'Copyrights',
    computed: {
		siteSettings() {
			return this.$store.getters.siteSettings;
		},
		domainName() {
			return this.$store.getters.domainName;
		}
	},
}
</script>