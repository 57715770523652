<template>
    <!-- login up Modal -->
    <div class="login-sign-up">
        <div class="modal fade" id="login" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered model-thm-width">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="form-get">
                            <div class="login-img-bg">
                                <div v-if="siteSettings" class="login-img-logo">
                                    <img :src="siteSettings.aws_url +
                                        siteSettings.storage_path.domain_image +
                                        siteSettings.logo
                                        " alt="logo.png" loading="lazy" />
                                </div>
                            </div>
                            <div class="login-page">
                                <div class="crox-but">
                                    <button ref="close_btn" @click="refreshData()" type="button" class="close"
                                        data-bs-dismiss="modal" aria-label="Close">
                                        x
                                    </button>
                                </div>
                                <div class="login-now">
                                    <form id="login">
                                        <div class="mak-gin text-danger">
                                            <ul v-if="siteSettings?.business_type == 2" class="nav nav-pills mb-1"
                                                id="pills-tab" role="tablist">
                                                <li class="nav-item" role="presentation">
                                                    <button @click="form.type = 1" class="nav-link active"
                                                        id="pills-home-tab" data-bs-toggle="pill"
                                                        data-bs-target="#pills-home" type="button" role="tab"
                                                        aria-controls="pills-home" aria-selected="true">
                                                        By phone number
                                                    </button>
                                                </li>
                                                <li class="nav-item" role="presentation">
                                                    <button @click="form.type = 2" class="nav-link" id="pills-profile-tab"
                                                        data-bs-toggle="pill" data-bs-target="#pills-profile" type="button"
                                                        role="tab" aria-controls="pills-profile" aria-selected="false">
                                                        By user id
                                                    </button>
                                                </li>
                                            </ul>
                                            <div class="tab-content" id="pills-tabContent">
                                                <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                                                    aria-labelledby="pills-home-tab">
                                                    <div class="user-phone">
                                                        <div class="row g-1">
                                                            <div class="col-3 col-lg-3">
                                                                <select v-model="form.code"
                                                                    class="form-select form-control codein"
                                                                    aria-label="Default select example"
                                                                    name="countrycodewhatsapp" id="countrycodewhatsapp">
                                                                    <option :value="allowedCountry.phonecode" v-for="(
                                      allowedCountry, countryIndex
                                    ) in siteSettings?.country_code_list" :key="countryIndex">
                                                                        +{{ allowedCountry.phonecode }}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <div class="col-9 col-lg-9">
                                                                <div class="whatsup-sec">
                                                                    <div class="input-left">
                                                                        <input v-model="form.mobileNo" type="text"
                                                                            class="form-control" id="mobile" name="mobile"
                                                                            required />
                                                                    </div>
                                                                    <span v-if="showValidationError && getId() == ''"
                                                                        class="text-danger">Please enter mobile
                                                                        number</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="pills-profile" role="tabpanel"
                                                    aria-labelledby="pills-profile-tab">
                                                    <div class="user-id">
                                                        <div class="input-field c-pass">
                                                            <label class="active">Username</label>
                                                            <input v-model="form.userId" class="validate" type="text" />
                                                        </div>
                                                        <span v-if="showValidationError && getId() == ''"
                                                            class="text-danger">Please enter user id</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="input-field c-pass">
                                                <label class="active">Enter your Password</label>
                                                <input v-model="form.password" class="validate" id="password"
                                                    :type="form.passwordVisible ? 'text' : 'password'" name="password"
                                                    required="" aria-invalid="false" />
                                                <div class="score-hide-show">
                                                    <img loading="lazy" @click="
                                                        form.passwordVisible = !form.passwordVisible
                                                        " class="score-hide-icon"
                                                        src="@/assets/images/score-visible.svg" v-if="form.passwordVisible"
                                                        alt="img" />
                                                    <img loading="lazy" v-else @click="
                                                        form.passwordVisible = !form.passwordVisible
                                                        " class="score-hide-icon"
                                                        src="@/assets/images/score-hide-icon.svg" alt="img" />
                                                </div>
                                            </div>
                                            <span v-if="showValidationError && form.password == ''"
                                                class="text-danger">Please enter password</span>

                                            <Captcha :showValidationError="showValidationError" :form="form" />

                                            <div class="mak-gin forgot-password-link">
                                                <div class="forpass">
                                                    <a href="getnewpass" class="getnewpass" data-bs-toggle="modal"
                                                        data-bs-target="#getnewpass">Forgot Password?</a>
                                                </div>
                                            </div>

                                            <div>
                                                <button type="button" @click="signInMethodCall()"
                                                    class="btn thm-but place-but mt-lg-3 mt-2 w-100"
                                                    :class="{ 'btn-loading': loading }">
                                                    Sign In
                                                    <span><b></b><b></b><b></b></span>
                                                </button>
                                                <button type="button" @click="demoUserLogin()"
                                                    class="btn thm-but place-but mt-lg-3 mt-2 w-100"
                                                    :class="{ 'btn-loading': demoLoading }">
                                                    Demo Sign In
                                                    <span><b></b><b></b><b></b></span>
                                                </button>
                                            </div>
                                            <div class="logon-option">
                                                <div class="flex-between">
                                                    <div class="border-back left-side"></div>
                                                    <div class="or-login-with">or Login with</div>
                                                    <div class="border-back"></div>
                                                </div>
                                                <ul class="submenu social-icons mb-3">
                                                    <li>
                                                        <a href="javascript:void(0)" rel="noopener" class="facebook"><img
                                                                src="@/assets/images/facebook-logo.svg" alt="" /></a>
                                                    </li>
                                                    <li>
                                                        <a href="javascript:void(0)" rel="noopener" class="instagram"><img
                                                                src="@/assets/images/google-logo.svg" alt="" /></a>
                                                    </li>
                                                    <li>
                                                        <a href="javascript:void(0)" rel="noopener" class="twitter"><img
                                                                src="@/assets/images/instagram-logo.svg" alt="" /></a>
                                                    </li>
                                                    <li>
                                                        <a href="javascript:void(0)" class="email">
                                                            <img src="@/assets/images/telegram-logo.svg" alt="" />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div v-if="siteSettings &&
                                                siteSettings.social_link &&
                                                siteSettings?.social_link.whatsappno1
                                                " class="col-auto">
                                                <h3 class="whats-with">
                                                    Or Continue With
                                                    <span class="whats-with-now"> Whatsapp</span>
                                                </h3>
                                                <div class="button-whatsapp">
                                                    <div class="whatsapp_btn">
                                                        <a type="button" :href="'//wa.me/' + siteSettings.social_link.whatsappno1
                                                            " target="_blank" class="btn-whatsapps">
                                                            <img src="@/assets/images/whatsapps.png" alt=""
                                                                loading="lazy" /> Whatsapp Now </a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="register-tutorial hide-on-large-only ng-star-inserted">
                                                <a @click="chooseUserJourney(siteSettings.user_journey.register_video, 'How to Login')" class="tutorial-btn">How To login?</a>
                                            </div>
                                            <p class="forpass-in account">
                                                Already not have an account?
                                                <a href="#register1" class="join-now join-page jpin-text"
                                                    data-bs-toggle="modal" data-bs-target="#register1">Join Now</a>
                                            </p>
                                        </div>
                                    </form>
                                </div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div ref="show_user_journey_video" style="display: none;" data-bs-toggle="modal"
		data-bs-target="#show_user_journey_video">
	</div>

    <div class="modal fade game-play-modal" @click="showUserJourneyVideo = false" id="show_user_journey_video" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog ">
            <VideoModal v-if="showUserJourneyVideo" :title="choosedUserJourney ? choosedUserJourney.title : null"
                :link="choosedUserJourney ? choosedUserJourney.link : null" />
        </div>
    </div>
</template>

<script>
import { AUTH_TOKEN } from "@/shared/constants/cookies-const";
import api from "../services/api";
import * as apiName from "../services/urls";
import axios from "axios";
import { setHeaders } from "@/shared/services/headers";
import Captcha from "./Captcha.vue";
import { mapGetters } from "vuex";
import captchaApi from "../services/captcha-api";
import { Modal } from "bootstrap";
import VideoModal from "@/shared/components/modal/VideoModal.vue";

export default {
    name: "Login",
    computed: {
        ...mapGetters({
            siteSettings: "siteSettings",
        }),
    },
    data() {
        return {
            form: {
                code: "91",
                mobileNo: "",
                userId: "",
                password: "",
                passwordVisible: false,
                type: 1,
                captchaText: "",
            },
            styleObject: {
                display: "none",
            },
            showValidationError: false,
            loading: false,
            demoLoading: false,
            modal: null,
            choosedUserJourney: { link: null, title: null },
			showUserJourneyVideo: false,
        };
    },
    mounted() {
        if (this.$route.name == "login" && !this.checkIsLogin()) {
            let loginModal = new Modal("#login");
            loginModal.show();
        } else if (this.$route.name == "login" && this.checkIsLogin()) {
            this.$router.push("/");
        }
    },
    methods: {
        refreshData() {
            (this.form = {
                code: "91",
                mobileNo: "",
                userId: "",
                password: "",
                type: 1,
                captchaText: "",
            }),
                (this.showValidationError = false);
        },
        demoUserLogin() {
            this.form.type = 1;
            this.form.userid = "demouser";
            this.form.password = "demopass";
            this.form.captchaToken = "demoCaptchaToken";
            this.form.captchaId = "demoCaptchaId";
            this.loginServiceCall(apiName.DEMO_LOGIN_API, "demo");
        },
        signInMethodCall() {
            debugger;
            if (!this.loading) {
                let id = this.getId();
                if (
                    id == "" ||
                    this.form.password == "" ||
                    this.form.captchaText == ""
                ) {
                    this.showValidationError = true;
                } else {
                    this.loginServiceCall(apiName.LOGIN_API, "nondemo");
                }
            }
        },
        chooseUserJourney(userJourneLink, title) {
			if (userJourneLink) {
				userJourneLink = this.siteSettings.aws_url + this.siteSettings.storage_path.user_journey_path + userJourneLink;
			}
			this.choosedUserJourney = { link: userJourneLink, title: title };
			//this.$bvModal.show('user_journey');
			this.showUserJourneyVideo = true;
			this.$refs.show_user_journey_video.click();
		},
        loginServiceCall(url, type) {
            let id = this.getId();
            if (type == "demo") {
                this.demoLoading = true;
            } else {
                this.loading = true;
            }
            let captchaData = this.$store.getters.captchaData;
            let data = {
                userid: id.toString(),
                type: this.siteSettings.business_type == 1 ? 2 : this.form.type,
                password: this.form.password,
                captchaToken: this.form.captchaText,
                captchaId: captchaData.id,
                country_code: this.form.code,
            };

            api
                .post(url, data)
                .then((response) => {
                    if (type == "demo") {
                        this.demoLoading = false;
                    } else {
                        this.loading = false;
                    }
                    if (response) {
                        if (response.status == 200) {
                            if (response?.data?.status == 0) {
                                this.showErrorModalFunc(response.data.debug[0]);
                            } else {
                                this.showSuccessModalFunc(response.data.message);
                                let userData = response.data.data;
                                this.$cookies.set(AUTH_TOKEN, userData.token);
                                this.$store.dispatch("setToken", `Bearer ${userData.token}`);
                                window.authorization_token = `Bearer ${this.$cookies.get(
                                    AUTH_TOKEN
                                )}`;
                                //for add on all APIs
                                axios.defaults.headers.common[
                                    "Authorization"
                                ] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                                setHeaders();
                                this.setUserDataToStore(userData);
                                this.$store.dispatch("setCaptchaData", null);
                                this.refreshData();
                                //this.$router.go();
                                location.reload();
                                this.closeModal();
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.log("Error Login : ", error);
                    if (type == "demo") {
                        this.demoLoading = false;
                    } else {
                        this.loading = false;
                    }
                    this.$store.dispatch("setCaptchaData", null);
                    captchaApi.getCaptchaDataServiceCall();
                    if (error) {
                        this.showErrorModalFunc(error[0]);
                    }
                });
        },
        clickOnType(type) {
            this.form.type = type;
            this.styleObject.display = "none";
            this.form.userId = "";
            this.form.mobileNo = "";
        },
        getId() {
            if (this.siteSettings.business_type == 2) {
                return this.form.type == 1 ? this.form.mobileNo : this.form.userId;
            } else {
                return this.form.userId;
            }
        },
        showErrorModalFunc(message) {
            this.$emit("error-modal", message);
        },
        showSuccessModalFunc(message) {
            this.$emit("success-modal", message);
        },
        closeModal() {
            this.$refs.close_btn.click();
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
        setUserDataToStore(userData) {
            let user = {
                balance: userData.balance,
                currency: userData.currency,
                currency_slug: userData.currency_slug,
                last_login_at: userData.last_login_at,
                name: userData.name,
                role_id: userData.role_id,
                userid: userData.userid,
                exposure: userData.exposure,
                available_credits: userData.available_credits,
                id: userData.id,
                exp: userData.exp,
            };
            this.$store.dispatch("setUser", user);
            this.$store.dispatch("setChips", userData.chips);
            this.$store.dispatch("setEventManage", userData.event_manage);
        },
    },
    components: { Captcha, VideoModal },
};
</script>

<style scoped>
div.mm-dropdown ul li {
    display: flex !important;
    padding-left: 10px;
}
</style>