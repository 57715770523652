const StaticModule = () => import(/* webpackChunkName: "static-module" */ './views/Module.vue');
const Static = () => import(/* webpackChunkName: "static" */ './views/Static.vue');

const StaticRoutes = {
    path: '/',
    component: StaticModule,
    children: [
        {
            path: 'static/:type',
            name: 'static',
            component: Static,
        },
    ],
}

export default StaticRoutes;