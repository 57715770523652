<template>
    <section class="marquee-sec">
        <div class="container">
            <div class="news-feed">
                <span class="title"><img src="@/assets/images/bell.png" alt="" loading="lazy" /> News : </span>
                <marquee behavior="scroll" direction="left" scrollamount="8" class="marquee-style">
                    <div class="marquee-news">
                        <span class="marquee-news-text">
                            👉👉GET 4% REFILL BONUS ON EVERY DEPOSIT FOR DEPOSIT CONTACT ON YOUR AGENCY NUMBER THROUGH
                            WHATSAPP👈👈"To VERIFY YOUR BRANCH DEPOSIT/WITHDRAW NUMBER. CONTACT HELP DESK NO.+91 7741000000"
                        </span>
                    </div>
                </marquee>
            </div>
        </div>
    </section>
</template>
<script>
export default{
    name:'NewsSection'
}
</script>