<template>
    <div class="login-sign-up">
        <div class="modal fade modal-footer-sticky" id="changepasswordModal" tabindex="-1"
            aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog">
            <div class="modal-dialog login-modal-res modal-dialog-centered">
                <div class="modal-content login-modal-sec ">
                    <div class="modal-header border-0">
                        <div class="thm-heading">
                            <h3>Change password</h3>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body border-radius">
                        <form>
                            <div class="input-field c-pass">
                                <label class="active">Old Password</label>
                                <input :type="oldPasswordVisible ? 'text' : 'password'" v-model="oldPassword"
                                    class="bg-transparent-input" placeholder="Enter here" aria-label="Username"
                                    aria-describedby="addon-wrapping">
                                <div class="score-hide-show">
                                    <img loading="lazy" @click="oldPasswordVisible = !oldPasswordVisible"
                                        class="score-hide-icon" src="@/assets/images/score-visible.svg"
                                        v-if="oldPasswordVisible" alt="img">
                                    <img loading="lazy" v-else @click="oldPasswordVisible = !oldPasswordVisible"
                                        class="score-hide-icon" src="@/assets/images/score-hide-icon.svg" alt="img">
                                </div>
                            </div>
                            <span v-if="showValidationError && oldPassword == ''" class="text-danger">Please enter old
                                password.</span>

                            <div class="input-field c-pass">
                                <label class="active">New Password</label>
                                <input :type="newPasswordVisible ? 'text' : 'password'" v-model="newPassword" class="bg-transparent-input"
                                    placeholder="New Password " aria-label="Username" aria-describedby="addon-wrapping">
                                <div class="score-hide-show">
                                    <img loading="lazy" @click="newPasswordVisible = !newPasswordVisible"
                                        class="score-hide-icon" src="@/assets/images/score-visible.svg"
                                        v-if="newPasswordVisible" alt="img">
                                    <img loading="lazy" v-else @click="newPasswordVisible = !newPasswordVisible"
                                        class="score-hide-icon" src="@/assets/images/score-hide-icon.svg" alt="img">
                                </div>

                            </div>
                            <span v-if="showValidationError && newPassword == ''" class="text-danger">Please enter new
                                password.</span>

                            <div class="input-field c-pass">
                                <label class="active">Confirm Password</label>
                                <input :type="confirmPasswordVisible ? 'text' : 'password'" v-model="confirmPassword" class="bg-transparent-input"
                                    placeholder="Confirm Password" aria-label="Username" aria-describedby="addon-wrapping">
                                    <div class="score-hide-show">
                                    <img loading="lazy" @click="confirmPasswordVisible = !confirmPasswordVisible"
                                        class="score-hide-icon" src="@/assets/images/score-visible.svg"
                                        v-if="confirmPasswordVisible" alt="img">
                                    <img loading="lazy" v-else @click="confirmPasswordVisible = !confirmPasswordVisible"
                                        class="score-hide-icon" src="@/assets/images/score-hide-icon.svg" alt="img">
                                </div>
                            </div>
                            <span v-if="showValidationError && newPassword != confirmPassword" class="text-danger">Password
                                and confirm password not matched.</span>


                            <div class="withdraw-fix-sec continue-btn edit-btn">
                                <a class="thm-bor-but thm-but" data-bs-dismiss="modal" aria-label="Close">Cancel</a>
                                <a @click="submitChangePassword()" class="thm-but">Save</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from '../services/api';
import * as apiName from '../services/urls';
import { AUTH_TOKEN } from "@/shared/constants/cookies-const";

export default {
    name: 'ChangePassword',
    data() {
        return {
            oldPassword: '',
            oldPasswordVisible: false,
            newPassword: '',
            newPasswordVisible: false,
            confirmPassword: '',
            confirmPasswordVisible: false,
            showValidationError: false,
            passwordValidation: false,
        }
    },
    methods: {
        submitChangePassword() {
            if (this.oldPassword == '' || this.newPassword == '' || this.confirmPassword == '') {
                this.showValidationError = true;
            }
            else if (this.newPassword != this.confirmPassword) {
                this.passwordValidation = true;
            }
            else {
                this.callChangePasswordServiceFunction();
            }
        },
        callChangePasswordServiceFunction() {

            this.loading = true;

            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }

            let data = {
                "current_password": this.oldPassword,
                "new_password": this.newPassword,
                "confirm_password": this.confirmPassword
            };
            api.post(apiName.CHANGE_MY_PASSWORD, data, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {

                            this.$emit('success-modal', response.data.message);
                            //let userData = response.data.data;
                            // this.$cookies.set(AUTH_TOKEN, userData.token);
                            // axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                            // this.setUserDataToStore(userData);
                            // this.refreshData();
                            this.closeModal();
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        closeModal() {

            this.$refs.close_btn.click();
        }
    }
}

</script>