<template>
    <!-- sign up Modal -->
    <div class="forgot-password login-sign-up Register">
        <div class="modal fade" id="register1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered model-thm-width">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="form-get" v-if="siteSettings">
                            <div class="login-img-bg">
                                <div class="login-img-logo">
                                    <img :src="siteSettings.aws_url + siteSettings.storage_path.domain_image + siteSettings.logo"
                                        alt="logo.png" loading="lazy" />
                                </div>
                            </div>
                            <div class="login-page">
                                <div class="crox-but">
                                    <button @click="refreshData()" type="button" class="close" data-bs-dismiss="modal"
                                        aria-label="Close">x</button>
                                </div>
                                <div class="login-now">
                                    <form id="login">
                                        <div class="mak-gin text-danger">
                                            <div class="row g-1">
                                                <div class="col-12 col-lg-12">
                                                    <div class="login-flow-heading register-text">
                                                        <h1 class="login-form">Register</h1>
                                                        <p class="pb-2">Get your ready-made ID from whatsapp</p>
                                                    </div>
                                                    <div class="button-whatsapp ">
                                                        <div class="whatsapp_btn">
                                                            <a type="button" :href="'//wa.me/' + siteSettings?.social_link?.whatsappno1
                                                                " target="_blank" class="btn-whatsapps">
                                                                <img src="@/assets/images/whatsapps.png" alt=""
                                                                    loading="lazy" /> Whatsapp Now </a>

                                                        </div>
                                                        <p class="or-text pt-2">OR create account with Mobile Number</p>
                                                    </div>
                                                </div>
                                                <div class="col-3 col-lg-3">
                                                    <select v-model="formMobile.code"
                                                        class="form-select form-control codein"
                                                        aria-label="Default select example" name="countrycodewhatsapp"
                                                        id="countrycodewhatsapp">
                                                        <option :value="allowedCountry.phonecode"
                                                            v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                            :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                                    </select>
                                                </div>
                                                <div class="col-9 col-lg-9">
                                                    <div class="whatsup-sec">
                                                        <div class="input-left">
                                                            <input v-model="formMobile.mobileNo" type="number"
                                                                class="form-control" id="mobile" name="mobile"
                                                                placeholder="" required />
                                                        </div>
                                                        <span v-if="showErrorMobile && formMobile.mobileNo == ''"
                                                            class="text-danger">Please enter mobile
                                                            number</span>
                                                    </div>
                                                </div>

                                                <div class="col-12 mak-gin mo-number-right">
                                                    <p><span v-if="timerRunning" style="margin-right: 10px;">{{
                                                        formatTime(minutes) + ':' +
                                                        formatTime(seconds) }}</span></p>

                                                    <button :disabled="loadingOtp || timerRunning" type="button"
                                                        @click="sendOtpCall()" class="btn cmn-bdr-btn otp-btn"
                                                        :class="{ 'btn-loading': loadingOtp }">{{ resendOtp ? 'Resend OTP' :
                                                            'Get OTP' }}
                                                        <span><b></b><b></b><b></b></span></button>
                                                </div>
                                            </div>
                                            <div class="col-12" v-if="isOtpApiCalled">
                                                <div class="input-field c-pass">
                                                    <label class="active">OTP</label>
                                                    <input v-model="form.otp" class="validate" type="number" name="otp"
                                                        id="otp-input" required />
                                                </div>
                                                <span v-if="showValidationError && form.otp == ''"
                                                    class="text-danger">Please enter OTP.</span>
                                            </div>
                                            <div class="input-field c-pass">
                                                <label class="active">User Name</label>
                                                <input v-model="form.userName" class="validate" type="text" />
                                            </div>
                                            <span v-if="showValidationError && form.userName == ''"
                                                class="text-danger">Please enter username.</span>

                                            <div class="input-field c-pass">
                                                <label class="active">Password</label>
                                                <input v-model="form.password" class="validate"
                                                    :type="form.passwordVisible ? 'text' : 'password'" name="password"
                                                    id="password" required aria-describedby="password" />

                                                <div class="score-hide-show">
                                                    <img loading="lazy"
                                                        @click="form.passwordVisible = !form.passwordVisible"
                                                        class="score-hide-icon" src="@/assets/images/score-visible.svg"
                                                        v-if="form.passwordVisible" alt="img">
                                                    <img loading="lazy" v-else
                                                        @click="form.passwordVisible = !form.passwordVisible"
                                                        class="score-hide-icon" src="@/assets/images/score-hide-icon.svg"
                                                        alt="img">
                                                </div>

                                            </div>
                                            <span v-if="showValidationError && form.password == ''"
                                                class="text-danger">Please enter password.</span>

                                            <div class="input-field c-pass">
                                                <label class="active">Confirm Password</label>
                                                <input v-model="form.confirmPassword"
                                                    :type="form.confirmPasswordVisible ? 'text' : 'password'"
                                                    class="validate" name="confirm password" id="password" required
                                                    aria-describedby="password" />

                                                <div class="score-hide-show">
                                                    <img loading="lazy"
                                                        @click="form.confirmPasswordVisible = !form.confirmPasswordVisible"
                                                        class="score-hide-icon" src="@/assets/images/score-visible.svg"
                                                        v-if="form.confirmPasswordVisible" alt="img">
                                                    <img loading="lazy" v-else
                                                        @click="form.confirmPasswordVisible = !form.confirmPasswordVisible"
                                                        class="score-hide-icon" src="@/assets/images/score-hide-icon.svg"
                                                        alt="img">
                                                </div>

                                            </div>
                                            <span v-if="showValidationError && form.password != form.confirmPassword"
                                                class="text-danger">Password not matched.</span>

                                            <div class="input-field c-pass">
                                                <label class="active">Refer Code</label>
                                                <input v-model="form.referCode" class="validate" type="text"
                                                    name="refer code" id="refer_code" required aria-describedby="text" />

                                            </div>

                                            <Captcha v-if="!checkIsLogin() && isOtpApiCalled"
                                                :showValidationError="showValidationError" :form="form" />

                                            <button type="button" @click="signUpCall()"
                                                class="btn thm-but place-but mt-3 w-100"
                                                :class="{ 'btn-loading': loadingSignUp }" id="verifyBtn">
                                                Register
                                                <span><b></b><b></b><b></b></span>
                                            </button>
                                            <div class="register-tutorial hide-on-large-only ng-star-inserted">
                                                <a @click="chooseUserJourney(siteSettings.user_journey.register_video, 'How to Register')" class="tutorial-btn">How To Register?</a>
                                            </div>


                                            <p class="forpass-in account">
                                                Remember Your Password?
                                                <a href="#login" ref="open_login_modal" class="join-now join-page jpin-text"
                                                    data-bs-toggle="modal">Login</a>
                                            </p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div ref="show_user_journey_video" style="display: none;" data-bs-toggle="modal"
        data-bs-target="#show_user_journey_video">
    </div>

    <div class="modal fade game-play-modal" @click="showUserJourneyVideo = false" id="show_user_journey_video" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog ">
            <VideoModal :title="choosedUserJourney ? choosedUserJourney.title : null"
                :link="choosedUserJourney ? choosedUserJourney.link : null" />
        </div>
    </div>
</template>

<script>

import VideoModal from '@/shared/components/modal/VideoModal.vue';
import api from '../services/api';
import captchaApi from '../services/captcha-api';
import * as apiName from '../services/urls';
import Captcha from './Captcha.vue';
import { Modal } from "bootstrap"

export default {
    name: "SignUp",

    data() {
        return {
            formMobile: {
                code: this.siteSettings && this.siteSettings.country_code ? this.siteSettings.country_code : "91",
                mobileNo: "",
                captchaText: ''
            },
            form: {
                otp: '',
                userName: "",
                password: "",
                passwordVisible: false,
                confirmPassword: "",
                confirmPasswordVisible: false,
                referCode: this.getQueryParam("refer_code", window.location.href),
                captchaText: ''
            },
            showValidationError: false,
            showErrorMobile: false,
            passwordValidation: false,
            loadingSignUp: false,
            loadingOtp: false,
            captchaData: null,
            isOtpApiCalled: false,
            resendOtp: false,
            timerRunning: false,
            minutes: 2,
            seconds: 0,
            choosedUserJourney: { link: null, title: null },
			showUserJourneyVideo: false,
        };
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        }
    },
    mounted() {
        if (this.$route.name == 'sign-up' && !this.checkIsLogin()) {
            let loginModal = new Modal('#register1');
            loginModal.show();
        }
        else if (this.$route.name == 'sign-up' && this.checkIsLogin()) {
            this.$router.push('/')
        }
    },
    methods: {
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },
        refreshData() {
            this.formMobile = {
                code: this.siteSettings && this.siteSettings.country_code ? this.siteSettings.country_code : "91",
                mobileNo: "",
                captchaText: ''
            },
                this.form = {
                    otp: '',
                    userName: "",
                    password: "",
                    confirmPassword: "",
                    referCode: this.getQueryParam("refer_code", window.location.href),
                    captchaText: ''
                },
                this.showValidationError = false,
                this.showErrorMobile = false,
                this.passwordValidation = false,
                this.isOtpApiCalled = false,
                this.resendOtp = false,
                this.resendOtpTimer = 0,
                this.timerRunning = false,
                this.minutes = 0,
                this.seconds = 0
        },
        sendOtpCall() {
            if (!this.loadingOtp) {
                if (this.formMobile.mobileNo == '') {
                    this.showErrorMobile = true;
                } else {
                    this.sendOtpServiceCall();
                }
            }
        },
        sendOtpServiceCall() {
            this.loadingOtp = true;
            api.post(apiName.SEND_OTP, {
                mobile_number: this.formMobile.mobileNo,
                cncode: this.formMobile.code
            }).then(response => {
                this.loadingOtp = false;
                if (response) {
                    if (response.status == 200) {
                        this.isOtpApiCalled = true;
                        this.form.captchaText = '';
                        this.showErrorMobile = false;
                        this.showSuccessModalFunc(response.data.message)
                        this.resendOtp = true;
                        this.timerRunning = true;
                        this.minutes = 2;
                        this.seconds = 0
                        this.setResendTimer();
                    }
                }
            }).catch(error => {
                this.loadingOtp = false;
                this.$store.dispatch('setCaptchaData', null);
                captchaApi.getCaptchaDataServiceCall();
                if (error)
                    this.showErrorModalFunc(error[0])
            });
        },

        setResendTimer() {

            const countdown = () => {
                if (this.minutes === 0 && this.seconds === 0) {
                    this.timerRunning = false;
                    // Timer has expired, you can perform any action here
                } else {
                    if (this.seconds === 0) {
                        this.minutes--;
                        this.seconds = 59;
                    } else {
                        this.seconds--;
                    }
                    setTimeout(countdown, 1000);
                }
            };

            setTimeout(countdown, 1000);
        },

        formatTime(value) {
            return value < 10 ? `0${value}` : value;
        },

        signUpCall() {
            if (!this.isOtpApiCalled)
                return
            if (!this.loadingSignUp) {
                this.passwordValidation = false;
                if (this.formMobile.mobileNo == '') {
                    this.showErrorMobile = true;
                } else if (this.form.otp == '' || this.form.userName == '' || this.form.password == '' || this.form.confirmPassword == '' || this.form.captchaText == '') {
                    {
                        this.showValidationError = true;
                    }
                } else if (this.form.password != this.form.confirmPassword) {
                    this.passwordValidation = true;
                } else {
                    this.signUpServiceCall();
                }
            }
        },

        chooseUserJourney(userJourneLink, title) {
            if (userJourneLink) {
                userJourneLink = this.siteSettings.aws_url + this.siteSettings.storage_path.user_journey_path + userJourneLink;
            }
            this.choosedUserJourney = { link: userJourneLink, title: title };
            //this.$bvModal.show('user_journey');
            this.showUserJourneyVideo = true;
            this.$refs.show_user_journey_video.click();
        },

        signUpServiceCall() {
            this.loadingSignUp = true;
            let isDownLine = this.getQueryParam("downline", window.location.href);
            let captchaData = this.$store.getters.captchaData;
            let data = {
                "userid": this.form.userName,
                "phone": this.formMobile.mobileNo,
                "password": this.form.password,
                "confirm_password": this.form.confirmPassword,
                "otp": this.form.otp,
                "isDownLine": isDownLine ? isDownLine : false,
                "refer_code": this.form.referCode,
                "captchaToken": this.form.captchaText,
                "captchaId": captchaData?.id,
                "cncode": this.formMobile.code,
            };
            api.post(apiName.REGISTER, data).then(response => {
                this.loadingSignUp = false;
                if (response) {
                    if (response.status == 200) {
                        if (response.data.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        } else {
                            this.showSuccessModalFunc(response.data.message);
                            this.refreshData();
                            this.$store.dispatch('setCaptchaData', null);
                            captchaApi.getCaptchaDataServiceCall();
                            this.$refs.open_login_modal.click();

                        }
                    }
                }
            }).catch(error => {
                this.loadingSignUp = false;
                this.$store.dispatch('setCaptchaData', null);
                captchaApi.getCaptchaDataServiceCall();
                if (error)
                    this.showErrorModalFunc(error[0]);
            });
        },
        // getOtp() {
        //     return this.form.otp.toString().replaceAll(',', '');
        // },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        getQueryParam(paramName, url) {
            if (!url) url = location.href;
            paramName = paramName.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
            var regexS = "[\\?&]" + paramName + "=([^&#]*)";
            var regex = new RegExp(regexS);
            var results = regex.exec(url);
            return results == null ? null : results[1];
        },
        handleOtpBoxKeyDown(event, index) {
            if (event.key !== "Tab" && event.key !== "ArrowRight" && event.key !== "ArrowLeft") {
                event.preventDefault();
            }
            if (event.key === "Backspace") {
                this.form.otp[index] = null;
                if (index) {
                    this.$refs['otp-box-' + (index - 1)].focus();
                }
                return;
            }
            if ((new RegExp('^([0-9])$')).test(event.key)) {
                this.form.otp[index] = event.key;
                if (index != 5) {
                    this.$refs['otp-box-' + (index + 1)].focus();
                }
            }
        },

    },
    components: { Captcha, VideoModal }
};
</script>