<template>
    <div class="my-account-menu">
        <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div class="offcanvas-header">
                <button ref="close_button" type="button" class="close" data-bs-dismiss="offcanvas"
                    aria-label="Close">x</button>
                <div class="menu-content">
                    <div class="thm-heading">
                        <h3>{{ this.userData?.userid }}</h3>
                        <p>Last Login: {{ this.userData?.last_login_at }}</p>
                    </div>
                </div>
            </div>
            <div class="offcanvas-body">
                <div class="mene-details">
                    <div class="menu-details-list">
                        <ul>
                            <li>
                                <div class="menu-details-heading">
                                    <h4>Setting</h4>
                                </div>
                                <div class="menu-heading-con" style="display: contents;">
                                    <div v-if="clickBetCheck && getClickBetSelectedValue()" data-bs-toggle="modal"
                                        data-bs-target="#clickBetValue">
                                        <label>{{ getKFormatter(getClickBetSelectedValue()) }}</label>
                                    </div>
                                    <div class="form-check form-switch m-0 p-0">
                                        <input class="form-check-input" v-model="clickBetCheck"
                                            @change="betCheckChangeFunc()" type="checkbox" id="settingCheckDefaults" />
                                        <label class="form-check-label" for="flexSwitchCheckDefaults">On click bet</label>
                                    </div>
                                    <a style="display: none;" ref="open_click_bet_value" data-bs-toggle="modal"
                                        data-bs-target="#clickBetValue"></a>
                                </div>
                            </li>
                            <li>
                                <a href="#" class="w-100">
                                    <div class="wallet-balance-sec">
                                        <div class="menu-details-heading">
                                            <h4>Wallet balance</h4>
                                        </div>
                                        <div class="menu-details-heading">
                                            <h4><span class="profit-color border-0">₹ {{ this.userData?.balance
                                            }}</span></h4>
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <div class="menu-details-heading">
                                    <h4>Exposure</h4>
                                </div>
                                <div class="menu-details-heading">
                                    <h4><span style="color: #f44949;">₹ {{ this.userData?.expl }}</span></h4>
                                </div>
                            </li>
                            <li class="border-0">
                                <div class="menu-details-heading">
                                    <h4>Bonus Information</h4>
                                </div>
                                <div class="menu-details-heading">
                                    <h4>
                                        <span class="information-icon">
                                            <router-link @click="closeModal()" to="/bonus"> <img src="@/assets/images/info-icons.svg" alt=""
                                                    loading="lazy" /></router-link>
                                        </span>
                                    </h4>
                                </div>
                            </li>
                            <li>
                                <div class="bonus-sec">
                                    <div class="credits-list">
                                        <div class="credits-list-con">
                                            <h5>Earn Bonus</h5>
                                            <h6>₹ 8000</h6>
                                        </div>
                                        <div class="credits-list-con">
                                            <a href="#locked-bonus-modal"  data-bs-toggle="modal" style="box-shadow: none;">
                                                <h5>Locked Bonus</h5>
                                                <h6>₹ 400</h6>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="credits-chackn-box">
                                        <div class="menu-details-heading">
                                            <h4>
                                                Welcome Bonus <span class="information-icon"  data-bs-toggle="modal"
                                                    data-bs-target="#informationModal"> <img
                                                        src="@/assets/images/info-icons.svg" alt="" loading="lazy" /></span>
                                            </h4>
                                        </div>
                                        <div class="menu-heading-con welcome-bonus">
                                            <strong>₹ 500</strong>
                                        </div>
                                    </div>
                                    <div class="credits-chackn-box">
                                        <div class="menu-details-heading">
                                            <h4>Play with Bonus</h4>
                                        </div>
                                        <div class="menu-heading-con">
                                            <div class="form-check form-switch m-0 p-0">
                                                <input class="form-check-input" type="checkbox" id="settingCheckDefaults" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="menu-list">
                    <ul>
                        <li>

                            <div class="language-list">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/language-list-icon.svg" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <a data-bs-target="#language_selection_pop_up" data-bs-toggle="modal"
                                            class="notify-item">
                                            <h5>Language</h5>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </li>
                        <li>
                            <router-link @click="closeModal()" to="/wallet">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/wallet.png" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>Wallet</h5>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li>
                            <router-link @click="closeModal()" to="/notifications">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/notification.png" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>notification</h5>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li>
                            <router-link @click="closeModal()" :to="'/bets/' + types.PAGE_TYPE.USER_PROFILE">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/account-icon.svg" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>profile</h5>
                                    </div>
                                </div>
                            </router-link>
                        </li>

                        <li>
                            <router-link @click="closeModal()" :to="'/bets/' + types.PAGE_TYPE.WITHDRAW">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/slidee-3.svg" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>Withdraw</h5>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li>
                            <router-link @click="closeModal()" :to="'/bets/' + types.PAGE_TYPE.OPEN_BETS">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/slide-6.svg" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>Open Bets</h5>
                                    </div>
                                </div>
                            </router-link>
                        </li>

                        <li>
                            <router-link @click="closeModal()" :to="'/bets/' + types.PAGE_TYPE.CHIP_SETTING">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/slide-7.svg" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5> Chip Setting</h5>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li>
                            <router-link @click="closeModal()" to="/refer-earn">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/bonus-icons.svg" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>Refer and Earn</h5>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li>
                            <router-link @click="closeModal()" :to="'/bets/' + types.PAGE_TYPE.ACCOUNT_STATEMENT">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/profit-loss-icon.svg" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>account statement</h5>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li>
                            <router-link @click="closeModal()" :to="'/bets/' + types.PAGE_TYPE.SETTLED_BETS">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/settled-icons.svg" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>settled Bets</h5>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <li>
                            <router-link @click="closeModal()" :to="'/bets/' + types.PAGE_TYPE.PROFIT_LOSS">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/profitloss.svg" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>profit/loss</h5>
                                    </div>
                                </div>
                            </router-link>
                        </li>
                        <!-- <li>
                            <a data-bs-toggle="modal" href="#editstackModal">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/stack-limit-icon.svg" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>Edit Stack</h5>
                                    </div>
                                </div>
                            </a>
                        </li> -->

                        <li>
                            <a data-bs-toggle="modal" href="#changepasswordModal">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/change-password-icon.svg" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>Change Password</h5>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li class="logout-sec">
                            <a data-bs-toggle="modal" data-bs-target="#logoutmodal">
                                <div class="menu-con">
                                    <div class="menu-icon">
                                        <img src="@/assets/images/logout-icon.svg" alt="" loading="lazy" />
                                    </div>
                                    <div class="menu-icon-con">
                                        <h5>logout</h5>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <StakeModal @close-stake-modal="closeStakeModal()" @success-modal="showSuccessModalFunc"
        @error-modal="showErrorModalFunc" />


    <ClickBetModal @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" />

    <ChangePassword @close="closeModal()" @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" />
    <CreditReference />
    <div class="modal fade modal-footer-sticky" ref="siteRulesModal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <RulesModal :siteRuleData='siteRuleData' />
    </div>
    <LogoutModal @close="closeModal()" @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" />

    <LockedBonus @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" />
    <WelcomBonus  @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" />
</template>
<script>

import BonusInformation from './BonusInformation.vue';
import StakeModal from './StakeModal.vue';
import ChangePassword from './ChangePassword.vue';
import CreditReference from './CreditReference.vue';
import RulesModal from './RulesModal.vue';
import LogoutModal from './LogoutModal.vue'
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import axios from 'axios';
import { setHeaders } from '@/shared/services/headers';
import ClickBetModal from './ClickBetModal.vue';
import { kFormatter } from '@/shared/utils/formatter';
import api from '../services/api';
import * as apiName from '../services/urls';
//import { Translator } from 'vue-google-translate';
//import {Translator} from "vue-gtranslate";
import { Modal } from 'bootstrap';
import { mapGetters } from 'vuex';
import * as types from '@/modules/bets/utils/constants'
import LockedBonus from '@/shared/components/header/LockedBonus.vue';
import WelcomBonus from '@/modules/bonus/components/WelcomBonus.vue';

export default {
    name: "Account",
    data() {
        return {
            siteRuleModal: null,
            userData: null,
            themeSelection: false,
            clickBetCheck: false,
            themeName: '',
            arrayOfCountries: null,
            siteRuleData: '',
            isMountedComponent: false,
        }
    },
    computed: {
        ...mapGetters({
            getNotification: 'getNotification'
        }),
        types() {
            return types;
        }
    },
    mounted() {
        if (this.getClickBetValue()) {
            this.clickBetCheck = true
        }
        this.isMountedComponent = true;
        this.siteRulesModal();
        this.userData = this.$store?.getters?.stateUser;
        window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
        //for add on all APIs
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
        if (localStorage.getItem('default_class')) {
            this.themeName = localStorage.getItem('default_class');
        }
        setHeaders();
        this.arrayOfCountries = [
            {
                code: 'en|af',
                title: 'Afrikaans',
            },
            {
                code: 'en|ar',
                title: 'Arabic',
            },
            {
                code: 'en|ko',
                title: 'Korean',
            },
            {
                code: 'en|lt',
                title: 'Lithuanian',
            },
        ]
    },
    components: {
    BonusInformation,
    StakeModal,
    ChangePassword,
    CreditReference,
    RulesModal,
    ClickBetModal,
    LogoutModal,
    LockedBonus,
    WelcomBonus
},
    methods: {
        getGlobaLoader() {
            return this.$store.getters.globalLoader;
        },
        siteRulesModal() {
            this.siteRuleModal = new Modal(this.$refs.siteRulesModal);
        },
        betCheckChangeFunc() {
            if (this.clickBetCheck) {
                this.$refs.open_click_bet_value.click();
            }
            else {
                this.$store.dispatch('setClickBetValue', null);
            }
        },
        closeModal() {
            this.$refs.close_button.click();
        },
        closeStakeModal() {
            this.showStakeModal = false;
        },
        getClickBetValue() {
            return this.$store.getters.clickBetValue ? [...this.$store.getters.clickBetValue] : null;
        },

        getClickBetSelectedValue() {
            let inputValues = this.getClickBetValue();
            if (inputValues)
                return inputValues.filter(item => item.isSelected)[0].value;
        },
        getKFormatter(num) {
            return kFormatter(num);
        },

        setTheme(theme) {
            this.themeName = theme;
            localStorage.setItem('default_class', theme)
            document.documentElement.className = theme;
            this.themeSelection = false;
        },

        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },

        getRules() {
            this.$store.dispatch('setGlobalLoader', true);
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.GET_SITE_RULES, { headers }).then(response => {
                this.$store.dispatch('setGlobalLoader', false);
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            this.siteRuleData = response.data.data.rules;
                            this.siteRuleModal.show();
                            // this.$emit('rulesModal');
                            // this.closeModal();
                        }
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
    },

};
</script>