const BetsModule = () => import(/* webpackChunkName: "bets-module" */ './views/Module.vue');
const Bets = () => import(/* webpackChunkName: "bets" */ './views/Bets.vue');
const MatchMarket = () => import(/* webpackChunkName: "match-market" */ './views/MatchMarket.vue');
const WinLoss = () => import(/* webpackChunkName: "win-loss" */ './views/WinLoss.vue');

const BetsRoutes = {
    path: '/',
    component: BetsModule,
    children: [
        {
            path: 'bets/:type',
            name: 'bets',
            component: Bets,
        },
        {
            path: 'match-market/:item',
            name: 'match-market',
            component: MatchMarket,
        },
        {
            path: 'win-loss',
            name: 'win-loss',
            component: WinLoss,
        }
    ],
}

export default BetsRoutes;