<template>
   <header class="header-wapper">
      <nav class="navbar navbar-expand-lg">
         <div class="container-fluid">
            <div class="header-wapper-item">
               <div class="header-logo">
                  <div v-if="siteSettings" class="logo">
                     <router-link to="/"><img class="logo"
                           :src="siteSettings.aws_url + siteSettings.storage_path.domain_image + siteSettings.logo"
                           alt="logo" /></router-link>
                  </div>
                  <div class="offcanvas-sec">
                     <div class="offcanvas-manu">
                        <a class="btn btn-primary" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExamplemenu"
                           aria-controls="offcanvasExamplemenu" role="button">
                           <img src="@/assets/images/bars.png" alt="" loading="lazy" />
                        </a>
                        <SideMenu></SideMenu>
                     </div>

                  </div>
               </div>
               <div v-if="!checkIsLogin()" class="header-but">
                  <button class="thm-btn thm-btn-1" type="button" data-bs-toggle="modal" data-bs-target="#login">Sign
                     in</button>
                  <button class="thm-btn thm-btn-1" type="button" data-bs-toggle="modal"
                     data-bs-target="#register1">Register</button>
               </div>
               <div v-else class="header-but">
                  <ul>
                     <li>
                        <div class="dropdown header-wallet-but flex-between">
                           <div class="wallet-btn">
                              <img src="@/assets/images/wallet.svg" alt="" loading="lazy" />
                              <span class="header-btn-text">{{ stateUser.balance }}</span>
                           </div>
                           <div class="droopdown-arrow">
                              <img src="@/assets/images/down-arrow.png" alt="" loading="lazy" />
                           </div>
                           <div class="dropdown-content">
                              <a href="wallet.html" class="drop flex-between">
                                 <div class="Withdrawable">Withdrawable (₹)</div>
                                 <div class="amount-text">0</div>
                              </a>
                              <a href="#" class="drop flex-between">
                                 <div class="Withdrawable">Exposure (₹)</div>
                                 <div class="amount-text">{{ stateUser.exposure }}</div>
                              </a>
                           </div>
                        </div>
                     </li>
                     <li>
                        <router-link to="/wallet-deposit" class="header-wallet-but blinking">
                           <img src="@/assets/images/add.svg" alt="" loading="lazy" class="add-dep" />
                           <span class="header-btn-text"> Deposit </span>
                        </router-link>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </nav>
   </header>
</template>

<script>

import SideMenu from "./SideMenu.vue";
import { mapGetters } from 'vuex';

export default {
   name: "Header",
   components: {
      SideMenu
   },
   data() {
      return {
         cricketFight_eventName: JSON.parse(localStorage.getItem('crick_fight_match')) ? JSON.parse(localStorage.getItem('crick_fight_match')).event_name : '',
         myContest_eventName: JSON.parse(localStorage.getItem('my_contest')) ? JSON.parse(localStorage.getItem('my_contest')).event_name : '',
         searchText: ""
      }
   },
   created() { },
   computed: {
      ...mapGetters({
         siteSettings: 'siteSettings',
         stateUser: 'stateUser'
      })
   },
   methods: {
      checkForBackArrow() {
         let routeName = this.$route.name;
         let routeType = this.$route.params.type;
         switch (routeName) {
            //checking for show three dot menu
            case 'Home':
            case 'my-market':
               return true;
            case 'games':
               return routeType == 'GAMES';
            default:
               return false
         }
      },
      getTitle() {
         let routeName = this.$route.name;
         if (routeName == 'sports') {
            let routeType = this.$route.params.type;
            switch (routeType) {
               case 'cricket':
                  return 'Cricket';
               case 'soccer':
                  return 'Soccer';
               case 'tennis':
                  return 'Tennis';
               case 'ASSEMBLY_ELECTION':
                  return 'Assembly Election';
               case 'IPL_WINNER':
                  return 'IPL Winner';
               case 'HORSE_RACE':
                  return 'Horse Race'
               case 'GREY_HOUND':
                  return 'Grey Hound'
               default:
                  return 'Sports';
            }
         }
         else if (routeName == 'games') {
            let routeType = this.$route.params.type;
            switch (routeType) {
               case 'LIVE_CASINO':
                  return 'Live Casino';
               case 'WORLD_CASINO':
                  return 'World Casino';
            }
         }
         else if (routeName == 'virtual-sport') {
            return 'Virtual Sports';
         }
         else if (routeName == 'sports-book') {
            return 'Sportsbook';
         }
         else if (routeName == 'matka') {
            return 'Matka';
         }
         else if (routeName == 'notifications') {
            return 'Notifications';
         }
         else if (routeName == 'favourites') {
            return 'Favourites';
         }
         else if (routeName == 'wallet') {
            return 'Wallet';
         }
         else if (routeName == 'wallet-deposit') {
            return 'Wallet Deposit';
         }
         else if (routeName == 'deposit-payment-method') {
            return 'Wallet Payment Method';
         }
         else if (routeName == 'wallet-history') {
            return 'Wallet History'
         }
         else if (routeName == 'wallet-history-detail') {
            return 'Transaction Detail'
         }
         else if (routeName == 'payment-method' || routeName == 'wallet-withdraw') {
            return 'Withdraw Details'
         }
         else if (routeName == 'add-account') {
            return 'Account Details'
         } else if (routeName == 'CricketFight') {
            return 'CRICKET FIGHT'
         }
         else if (routeName == 'MatchDetails' || routeName == 'PlaceBet') {
            return this.cricketFight_eventName
         }
         else if (routeName == 'MyContests') {
            return 'MY CONTESTS'
         }
         else if (routeName == 'MyContestBetDetails') {
            return this.myContest_eventName
         }
         else if (routeName == 'racing-category' || routeName == 'racing-list-details' || routeName == 'racing-carousel') {
            let routeType = this.$route.params.type;
            switch (routeType) {
               case 'HORSE_RACE':
                  return 'Horse Race';
               case 'GREY_HOUND':
                  return 'Grey Hound';
               default:
                  return 'Racing';
            }
         }

      },
      callVoiceSearchFlag() {
         this.$emit('voice-search-flag')
      },
      checkIsLogin() {
         return this.$store?.getters?.isAuthenticated;
      },
      goToHome() {
         this.$router.push({ name: 'Home' });
      },
      searchIcon() {
         if (this.searchText) {
            this.$emit('search-filter', this.searchText)
         }
      }
   },
};
</script>