<template>
    <!-- forgot Modal -->
    <!-- <div class="modal fade" id="getnewpass" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered login-modal-res">
            <div class="modal-content login-modal-sec">
                <div class="modal-header align-items-start">
                    <div class="modal-logo">
                        <a href="index.html">
                            <img src="@/assets/images/logo.png" alt="">
                        </a>
                    </div>
                    <div class="thm-heading d-inline-block">
                        <h3>Change Password</h3>
                        <p>Create account with mobile number</p>
                    </div>
                    <button ref="close_btn" @click="refreshData()" type="button" class="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <form>


                        <div class="mb-2" :class="{ 'password-wrong': showErrorMobile && form.mobileNo == '' }">
                            <label for="exampleInputEmail1" class="form-label">Phone Number</label>

                            <div class="phone-sec">
                                <div class="phone-country">
                                    <div class="mm-dropdown-con ">
                                        <select v-model="form.code" class="form-select textfirst-con "
                                            aria-label="Default select example">
                                            <option :value="allowedCountry.phonecode"
                                                v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="phone-input">
                                    <input type="number" v-model="form.mobileNo" class="form-control"
                                        id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Number">
                                </div>

                            </div>
                            <div v-if="showErrorMobile && form.mobileNo == ''"
                                class="thm-heading justify-content-start mt-2    password-wrong-con">
                                <p class="wrong-color">Please enter mobile number.</p>
                            </div>

                            <Captcha :showValidationError="showErrorMobile" :form="form" />

                            <div class="header-but justify-content-end  mt-3">
                                <span v-if="timerRunning" style="margin-right: 10px;">{{ formatTime(minutes) + ':' +
                                    formatTime(seconds) }}</span>
                                <button type="button" :disabled="loadingOtp || timerRunning" @click="sendOtpCall()"
                                    class="btn btn-primary place-but  border-0 w-25"
                                    :class="{ 'btn-loading': loadingOtp }">{{ resendOtp ? 'Resend OTP' : 'Get OTP' }}
                                    <span><b></b><b></b><b></b></span></button>
                            </div>
                        </div>

                        <div class="otp-sec mb-3" :class="{ 'password-wrong': showValidationError && getOtp().length < 6 }">
                            <div class="number-header ">
                                <label for="exampleInputEmail1" class="form-label">OTP</label>
                                <div class="otp-box-sec">
                                    <div class="otp-box-con">
                                        <input v-model="form.otp[0]" class="form-control" type="tel" maxlength="1"
                                            @keydown="handleOtpBoxKeyDown($event, 0)" ref="otp-box-0" placeholder="_">
                                    </div>
                                    <div class="otp-box-con">
                                        <input v-model="form.otp[1]" class="form-control" type="tel" maxlength="1"
                                            ref="otp-box-1" @keydown="handleOtpBoxKeyDown($event, 1)" placeholder="_">
                                    </div>
                                    <div class="otp-box-con">
                                        <input v-model="form.otp[2]" class="form-control" type="tel" maxlength="1"
                                            @keydown="handleOtpBoxKeyDown($event, 2)" ref="otp-box-2" placeholder="_">
                                    </div>
                                    <div class="otp-box-con">
                                        <input v-model="form.otp[3]" class="form-control" type="tel" maxlength="1"
                                            @keydown="handleOtpBoxKeyDown($event, 3)" ref="otp-box-3" placeholder="_">
                                    </div>
                                    <div class="otp-box-con">
                                        <input v-model="form.otp[4]" class="form-control" type="tel" maxlength="1"
                                            @keydown="handleOtpBoxKeyDown($event, 4)" ref="otp-box-4" placeholder="_">
                                    </div>
                                    <div class="otp-box-con">
                                        <input v-model="form.otp[5]" class="form-control" type="tel" maxlength="1"
                                            @keydown="handleOtpBoxKeyDown($event, 5)" ref="otp-box-5" placeholder="_">
                                    </div>
                                </div>

                            </div>
                            <div class="thm-heading justify-content-start mt-2 password-wrong-con"
                                v-if="showValidationError && getOtp().length < 6">
                                <p class="wrong-color">Please enter OTP.</p>
                            </div>
                        </div>

                        <div class="password-sec" :class="{ 'password-wrong': showValidationError && form.password == '' }">
                            <label for="exampleInputEmail1" class="form-label">Password</label>
                            <div class=" flex-nowrap position-relative">
                                <span class="password-icon"><img src="@/assets/images/password-icon.svg" alt=""></span>
                                <input type="password" v-model="form.password" class="form-control"
                                    placeholder="Enter password" aria-label="Username" aria-describedby="addon-wrapping">
                            </div>
                            <div v-if="showValidationError && form.password == ''"
                                class="thm-heading justify-content-start mt-2    password-wrong-con">
                                <p class="wrong-color">Please enter password.</p>
                            </div>
                        </div>

                        <div class="password-sec mt-3"
                            :class="{ 'password-wrong': (showValidationError && form.confirmPassword == '') || passwordValidation }">
                            <label for="exampleInputEmail1" class="form-label">Confirm Password</label>
                            <div class=" flex-nowrap position-relative">
                                <span class="password-icon"><img src="@/assets/images/password-icon.svg" alt=""></span>
                                <input type="password" v-model="form.confirmPassword" class="form-control"
                                    placeholder="Enter confirm password" aria-label="Username"
                                    aria-describedby="addon-wrapping">
                            </div>
                            <div class="thm-heading justify-content-start mt-2    password-wrong-con">
                                <p v-if="showValidationError && form.confirmPassword == ''" class="wrong-color">Please enter
                                    confirm password.</p>
                                <p v-if="passwordValidation && form.password != form.confirmPassword" class="wrong-color">
                                    Password and confirm password not matched.
                                </p>
                            </div>
                        </div>



                        <Captcha v-if="isOtpApiCalled" :showValidationError="showValidationError" :form="form" />


                        <button @click="forgetPasswordCall()" type="button" :class="{ 'btn-loading': loadingUpdate }"
                            class="btn btn-primary place-but mt-3 w-100">Update Password</button>

                    </form>

                </div>

            </div>
        </div>
    </div> -->

    <div class="forgot-password login-sign-up">
        <div class="modal fade" id="getnewpass" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered model-thm-width">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="form-get" v-if="siteSettings">
                            <div class="login-img-bg">
                                <div class="login-img-logo">
                                    <img :src="siteSettings.aws_url + siteSettings.storage_path.domain_image + siteSettings.logo"
                                        alt="logo.png" loading="lazy" />
                                </div>
                            </div>
                            <div class="login-page">
                                <div class="crox-but">
                                    <button ref="close_btn" @click="refreshData()" type="button" class="close"
                                        data-bs-dismiss="modal" aria-label="Close">x</button>
                                </div>
                                <div class="login-now">
                                    <form id="login">
                                        <div class="mak-gin text-danger">
                                            <div class="row g-1">
                                                <div class="col-12 col-lg-12">
                                                    <div class="login-flow-heading">
                                                        <h1 class="login-form">Forgot Password ?</h1>
                                                        <p>Enter your Phone and we'll send you a otp to get back into your
                                                            account</p>
                                                    </div>
                                                </div>
                                                <div class="col-3 col-lg-3">
                                                    <select v-model="formMobile.code"
                                                        class="form-select form-control codein"
                                                        aria-label="Default select example" name="countrycodewhatsapp"
                                                        id="countrycodewhatsapp">
                                                        <option :value="allowedCountry.phonecode"
                                                            v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                            :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                                    </select>
                                                </div>
                                                <div class="col-9 col-lg-9">
                                                    <div class="whatsup-sec">
                                                        <div class="input-left">
                                                            <input v-model="formMobile.mobileNo" type="number"
                                                                class="form-control" id="mobile" name="mobile"
                                                                placeholder="" required />
                                                        </div>
                                                        <span v-if="showErrorMobile && formMobile.mobileNo == ''"
                                                            class="text-danger">Please enter mobile number</span>
                                                    </div>
                                                </div>

                                                <div class="col-12 mak-gin mo-number-right">
                                                    <p><span v-if="timerRunning" style="margin-right: 10px;">{{
                                                        formatTime(minutes) + ':' +
                                                        formatTime(seconds) }}</span></p>

                                                    <button :disabled="loadingOtp || timerRunning" type="button"
                                                        @click="sendOtpCall()" class="btn cmn-bdr-btn otp-btn"
                                                        :class="{ 'btn-loading': loadingOtp }">{{ resendOtp ? 'Resend OTP' :
                                                            'Get OTP' }}
                                                        <span><b></b><b></b><b></b></span></button>
                                                </div>
                                            </div>
                                            <div class="col-12" v-if="isOtpApiCalled">
                                                <div class="input-field c-pass">
                                                    <label class="active">OTP</label>
                                                    <input v-model="form.otp" class="validate" type="number" name="otp"
                                                        id="otp-input" required />
                                                </div>
                                                <span v-if="showValidationError && form.otp == ''"
                                                    class="text-danger">Please enter OTP.</span>
                                            </div>
                                            <span v-if="showValidationError && form.userName == ''"
                                                class="text-danger">Please enter username.</span>

                                            <div class="input-field c-pass">
                                                <label class="active">Password</label>
                                                <input v-model="form.password" class="validate" :type="form.passwordVisible?'text':'password'"
                                                    name="password" id="password" required aria-describedby="password" />

                                                <div class="score-hide-show">
                                                    <img loading="lazy"
                                                        @click="form.passwordVisible = !form.passwordVisible"
                                                        class="score-hide-icon" src="@/assets/images/score-visible.svg"
                                                        v-if="form.passwordVisible" alt="img">
                                                    <img loading="lazy" v-else
                                                        @click="form.passwordVisible = !form.passwordVisible"
                                                        class="score-hide-icon" src="@/assets/images/score-hide-icon.svg"
                                                        alt="img">
                                                </div>
                                            </div>
                                            <span v-if="showValidationError && form.password == ''"
                                                class="text-danger">Please enter password.</span>

                                            <div class="input-field c-pass">
                                                <label class="active">Confirm Password</label>
                                                <input v-model="form.confirmPassword" :type="form.confirmPasswordVisible?'text':'password'" class="validate" type="password"
                                                    name="confirm password" id="password" required
                                                    aria-describedby="password" />

                                                <div class="score-hide-show">
                                                    <img loading="lazy"
                                                        @click="form.confirmPasswordVisible = !form.confirmPasswordVisible"
                                                        class="score-hide-icon" src="@/assets/images/score-visible.svg"
                                                        v-if="form.confirmPasswordVisible" alt="img">
                                                    <img loading="lazy" v-else
                                                        @click="form.confirmPasswordVisible = !form.confirmPasswordVisible"
                                                        class="score-hide-icon" src="@/assets/images/score-hide-icon.svg"
                                                        alt="img">
                                                </div>

                                            </div>
                                            <span v-if="showValidationError && form.password != form.confirmPassword"
                                                class="text-danger">Password not matched.</span>

                                            <Captcha v-if="!checkIsLogin() && isOtpApiCalled"
                                                :showValidationError="showValidationError" :form="form" />

                                            <button type="button" @click="forgetPasswordCall()"
                                                class="btn thm-but place-but mt-3 w-100"
                                                :class="{ 'btn-loading': loadingUpdate }" id="verifyBtn">
                                                Reset
                                                <span><b></b><b></b><b></b></span>
                                            </button>



                                            <p class="forpass-in account">
                                                Remember Your Password?
                                                <a href="#login" class="join-now join-page jpin-text"
                                                    data-bs-toggle="modal">Login</a>
                                            </p>
                                            <p class="forpass-in new-account-text">
                                                <a href="#register1" class="register1" data-bs-toggle="modal">Create New
                                                    Account</a>
                                            </p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../services/api';
import captchaApi from '../services/captcha-api';
import * as apiName from '../services/urls';
import Captcha from './Captcha.vue';
import { Modal } from "bootstrap"

export default {
    name: "ForgetPassword",
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        }
    },

    data() {
        return {

            formMobile: {
                code: this.siteSettings && this.siteSettings.country_code ? this.siteSettings.country_code : "91",
                mobileNo: "",
                captchaText: ''
            },

            form: {
                otp: '',
                password: "",
                passwordVisible:false,
                confirmPassword: "",
                confirmPasswordVisible:false,
                captchaText: ''
            },
            loadingOtp: false,
            loadingUpdate: false,

            showValidationError: false,
            showErrorMobile: false,
            passwordValidation: false,
            captchaData: null,
            isOtpApiCalled: false,
            resendOtp: false,
            timerRunning: false,
            minutes: 2,
            seconds: 0
        };
    },
    mounted(){
        if(this.$route.name == 'forget-password' && !this.checkIsLogin()){
            let loginModal = new Modal('#getnewpass');
            loginModal.show();
        }
        else if(this.$route.name == 'forget-password' && this.checkIsLogin()){
            this.$router.push('/')
        }
    },
    methods: {
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },
        refreshData() {
            this.formMobile = {
                code: this.siteSettings && this.siteSettings.country_code ? this.siteSettings.country_code : "91",
                mobileNo: "",
                captchaText: ''
            },
                this.form = {
                    otp: '',
                    password: "",
                    confirmPassword: "",
                    captchaText: ''
                },
                this.showValidationError = false,
                this.showErrorMobile = false,
                this.passwordValidation = false
            this.isOtpApiCalled = false,
                this.resendOtp = false,
                this.resendOtpTimer = 0,
                this.timerRunning = false,
                this.minutes = 0,
                this.seconds = 0
        },
        sendOtpCall() {
            if (this.formMobile.mobileNo == "") {
                this.showErrorMobile = true;
            }
            else {
                this.sendOtpServiceCall();
            }
        },
        handleOtpBoxKeyDown(event, index) {
            if (event.key !== "Tab" && event.key !== "ArrowRight" && event.key !== "ArrowLeft") {
                event.preventDefault();
            }
            if (event.key === "Backspace") {
                this.form.otp[index] = null;
                if (index) {
                    this.$refs['otp-box-' + (index - 1)].focus();
                }
                return;
            }
            if ((new RegExp('^([0-9])$')).test(event.key)) {
                this.form.otp[index] = event.key;
                if (index != 5) {
                    this.$refs['otp-box-' + (index + 1)].focus();
                }
            }
        },
        sendOtpServiceCall() {
            this.loadingOtp = true;
            let data = {
                "mobile_number": this.formMobile.mobileNo,
                "cncode": this.formMobile.code,
                "getUserId": ""               
            };
            api.post(apiName.SEND_OTP, data).then(response => {
                this.loadingOtp = false;
                if (response) {
                    if (response.status == 200) {
                        this.isOtpApiCalled = true;
                        this.form.captchaText = '';
                        this.showErrorMobile = false
                        this.showSuccessModalFunc(response.data.message);
                        this.resendOtp = true;
                        this.timerRunning = true;
                        this.minutes = 2;
                        this.seconds = 0
                        this.setResendTimer();
                        // this.closeModal();
                    }

                }
            }).catch(error => {
                this.loadingOtp = false;
                if (error) {
                    this.$store.dispatch('setCaptchaData', null);
                    captchaApi.getCaptchaDataServiceCall();
                    this.showErrorModalFunc(error[0]);
                }
            });
        },

        setResendTimer() {

            const countdown = () => {
                if (this.minutes === 0 && this.seconds === 0) {
                    this.timerRunning = false;
                    // Timer has expired, you can perform any action here
                } else {
                    if (this.seconds === 0) {
                        this.minutes--;
                        this.seconds = 59;
                    } else {
                        this.seconds--;
                    }
                    setTimeout(countdown, 1000);
                }
            };

            setTimeout(countdown, 1000);
        },

        formatTime(value) {
            return value < 10 ? `0${value}` : value;
        },

        forgetPasswordCall() {
            if (!this.isOtpApiCalled)
                return
            this.passwordValidation = false;
            if (this.formMobile.mobileNo == "") {
                this.showErrorMobile = true;
            }
            else if (this.form.otp == '' || this.form.password == '' || this.form.confirmPassword == '' || this.form.captchaText == '') {
                this.showValidationError = true;
            }
            else if (this.form.password != this.form.confirmPassword) {
                this.passwordValidation = true;
            }
            else {
                this.forgotServiceCall();
            }
        },
        forgotServiceCall() {
            this.loadingUpdate = true;
            let captchaData = this.$store.getters.captchaData;
            let data = {
                "phone": this.formMobile.mobileNo,
                "cncode": this.form.code,
                "password": this.form.password,
                "confirm_password": this.form.confirmPassword,
                "otp": this.form.otp,
                "captchaToken": this.form.captchaText,
                "captchaId": captchaData.id
            };
            api.post(apiName.FORGOT_PASSWORD, data).then(response => {
                this.loadingUpdate = false;
                if (response) {
                    if (response.status == 200) {
                        if (response.data.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        }
                        else {
                            this.showSuccessModalFunc(response.data.message);
                            this.$store.dispatch('setCaptchaData', null);
                            captchaApi.getCaptchaDataServiceCall();
                            this.refreshData();
                        }
                    }
                }
            }).catch(error => {
                this.loadingUpdate = false;
                this.$store.dispatch('setCaptchaData', null);
                captchaApi.getCaptchaDataServiceCall();
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        // getOtp() {
        //     return this.form.otp.toString().replaceAll(',', '');
        // },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        closeModal() {
            this.$refs.close_btn.click();
        },

    },
    components: { Captcha }
};
</script>