<template>
   <!--offcanvas-start -->
   <div class="offcanvas-sec offcanvas-manu">
      <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExamplemenu"
         aria-labelledby="offcanvasExamplemenuLabel">
         <div class="offcanvas-item">
            <div class="offcanvas-header">
               <div class="search-box">
                  <div class="sidebar-search">
                     <button ref="close_btn" type="button" class="back-img" data-bs-dismiss="offcanvas"><img
                           class="back-icon" src="@/assets/images/back-icon.png" alt="" loading="lazy" /></button>
                     <div class="search-box-container">
                        <div class="search-sec">
                           <span class="searchbtn"><img src="@/assets/images/search.png" alt="" loading="lazy" /></span>
                           <input class="chrome" @input="onChange($event)" placeholder="Events, Markets, and more"
                              id="price" />
                        </div>
                        <div v-if="searchResult && searchResult.matches && searchResult.matches.length != 0"
                           class="suggest-list" id="btn1">
                           <ul>
                              <li v-for="(match, index) in searchResult.matches">
                                 <a  @click="clickOnSportItem(match)" class="search-data-list">
                                    <div class="search-data-type">
                                       <div class="search-game-name"><b>{{ match.league_name }}</b></div>
                                       <div class="game-date text-right">
                                          <span class="alex-1"> {{ match.event_name }}</span>
                                       </div>
                                       <div class="game-date text-right game-teams">{{ timeConverter(match.event_date) }}</div>
                                    </div>
                                 </a>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div class="favorite-icon">
                     <nav>
                        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                           <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab"
                              data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home"
                              aria-selected="true">
                              <div class="favorite-img">
                                 <img src="@/assets/images/favorite.png" alt="" loading="lazy" />
                              </div>
                           </button>
                           <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile"
                              type="button" role="tab" aria-controls="nav-profile" aria-selected="false">
                              <div class="favorite-img my-cart-img">
                                 <img src="@/assets/images/calender.svg" alt="" loading="lazy" />
                              </div>
                           </button>
                        </div>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
         <div class="offcanvas-body">
            <div class="toggle-container">
               <button class="theme-btn light" @click="setTheme('light')" title="Light mode">
                  <div class="change-theme">
                     <img src="@/assets/images/light-mode.svg" alt="light" loading="lazy" />
                     <p class="theme-mode dark-light">Light mode</p>
                  </div>
               </button>
               <button class="theme-btn dark" @click="setTheme('dark')" title="Dark mode">
                  <div class="change-theme">
                     <img src="@/assets/images/dark-mode.svg" alt="dark" loading="lazy" />
                     <p class="theme-mode dark-text">Dark mode</p>
                  </div>
               </button>
            </div>
            <div class="promotions">
               <button type="button" class="promotions-blink click-bet-m" data-bs-toggle="modal"
                  data-bs-target="#one-click-bet">
                  <img src="@/assets/images/bet.svg.svg" alt="" loading="lazy" />
                  <span> one click bet </span>
               </button>

            </div>
            <div class="promotions">
               <a href="promotions.html" class="promotions-blink blink">
                  <img src="@/assets/images/aanounce.svg" alt="" loading="lazy" />
                  <span>promotions</span>
               </a>
            </div>
            <div class="cricket-item">
               <div class="tab-content" id="nav-tabContent">
                  <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                     <div id="accordian">
                        <ul v-for="(sport, n_index) in non_custom_sports" :key="n_index"
                           v-show="checkInEventMange(sport.slug)" class="show-dropdown">
                           <li class="main-heading" :class="{ 'active': sport.id == this.sportId }">
                              <a @click="getLeagues(sport)">
                                 <div class="cricket-item-img">
                                    <img loading="lazy" v-if="sport.id == 4" src="@/assets/images/cricket-img.png" alt="">
                                    <img loading="lazy" v-if="sport.id == 1"
                                       src="@/assets/images/sports-icons-hattrickk-2.png" alt="">
                                    <img loading="lazy" v-if="sport.id == 2"
                                       src="@/assets/images/sports-icons-hattrickk-1.png" alt="">
                                 </div>
                                 {{ sport?.name }}
                              </a>

                              <ul class="sub-item show-dropdown"
                                 :style="sport.id == this.sportId ? 'display: block' : 'display: none'">
                                 <li v-for="(league, l_index) in leagues_list" :key="l_index"
                                    class="sub-class icc-cricket-item">
                                    <a @click="getMatches(league, l_index)"><span>{{ l_index }}</span></a>
                                    <ul :style="leagueName == l_index ? 'display: block' : 'display: none'"
                                       class="icc-sec">
                                       <li v-for="(match, m_index) in matchesList" :key="m_index">
                                          <a @click="clickOnSportItem(match)"><span> {{ match.event_name }}</span></a>
                                       </li>
                                    </ul>
                                 </li>
                              </ul>

                           </li>
                        </ul>

                        <ul class="show-dropdown">
                           <li class="main-heading">
                              <router-link :to="{ name: 'racing-category', params: { type: 'HORSE_RACE' } }">
                                 <div class="cricket-item-img">
                                    <img src="@/assets/images/Horse-Racing.png" alt="" loading="lazy" />
                                 </div>
                                 Horse Racing
                              </router-link>
                           </li>
                        </ul>
                        <ul class="show-dropdown">
                           <li class="main-heading">
                              <router-link :to="{ name: 'racing-category', params: { type: 'GREY_HOUND_RACING' } }">
                                 <div class="cricket-item-img">
                                    <img src="@/assets/images/Greyhound-Racing.png" alt="" loading="lazy" />
                                 </div>
                                 Greyhound Racing
                              </router-link>
                           </li>
                        </ul>
                        <ul class="show-dropdown">
                           <li class="main-heading">
                              <a href="live-casino.html">
                                 <div class="cricket-item-img">
                                    <img src="@/assets/images/live-casino.svg" alt="" loading="lazy" />
                                 </div>
                                 Casino
                              </a>
                           </li>
                        </ul>
                        <ul class="show-dropdown">
                           <li class="main-heading">
                              <a href="matka.html">
                                 <div class="cricket-item-img">
                                    <img src="@/assets/images/matka-img.webp" alt="" loading="lazy" />
                                 </div>
                                 Matka
                              </a>
                           </li>
                        </ul>
                        <ul class="show-dropdown">
                           <li class="main-heading">
                              <a href="sportsbook.html">
                                 <div class="cricket-item-img">
                                    <img src="@/assets/images/sportsbook-icon.svg" alt="" loading="lazy" />
                                 </div>
                                 Sportsbook
                              </a>
                           </li>
                        </ul>
                        <ul class="show-dropdown">
                           <li class="main-heading">
                              <a href="virtual-sport.html">
                                 <div class="cricket-item-img">
                                    <img src="@/assets/images/virtual-icon.svg" alt="" loading="lazy" />
                                 </div>
                                 virtual-sport
                              </a>
                           </li>
                        </ul>


                     </div>

                  </div>
                  <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                     <div v-if="todayMatchesList && todayMatchesList.length != 0" class="upcoming-event ng-star-inserted">
                        <ul>
                           <li v-for="(match, index) in todayMatchesList" class="ng-star-inserted">
                              <a @click="clickOnSportItem(match)">
                                 <div class="date-time"><span class="date">Today</span><span class="time">{{
                                    dateTimeConverter(match.event_date) }}</span>
                                 </div>
                                 <div class="game-name">
                                    <div class="versis-img">
                                       <span>{{ match.event_name.split(' v ')[0] }}&nbsp;</span>
                                       <span class="vs-img notranslate ng-star-inserted">
                                          <img src="@/assets/images/team-vs.svg" alt="" loading="lazy"
                                             class="add-filter" />
                                       </span>
                                       <span class="ng-star-inserted">&nbsp;{{ match.event_name.split(' v ')[1] }}</span>
                                    </div>
                                    <span class="subtitle">
                                       <div class="icon-holder-verysmall">
                                          <div class="sports-icon cricket">
                                             <img loading="lazy" v-if="match.sport_id == 4"
                                                src="@/assets/images/cricket-img.png" alt="">
                                             <img loading="lazy" v-if="match.sport_id == 1"
                                                src="@/assets/images/sports-icons-hattrickk-2.png" alt="">
                                             <img loading="lazy" v-if="match.sport_id == 2"
                                                src="@/assets/images/sports-icons-hattrickk-1.png" alt="">
                                          </div>
                                       </div>
                                       {{ match.sport_name }} | {{ match.league_name }}
                                    </span>
                                 </div>
                              </a>
                           </li>
                        </ul>
                     </div>
                     <div v-if="todayMatchesList && todayMatchesList.length == 0">
                        <NoDataFound />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <one-clcik-bet />
</template>

<script>


import oddsApi from '../../services/click-api';
import api from '../../services/api';
import * as apiName from '../../services/urls';
import * as varConstants from '@/shared/constants/var-constants';
import moment from 'moment';
import NoDataFound from '../no-data/NoDataFound.vue';
import { Modal } from 'bootstrap';
import oneClickModal from '../modal/one-clcik-bet.vue'
import OneClcikBet from '../modal/one-clcik-bet.vue';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';

export default {
   components: {
      oneClickModal
   },
   name: "SideMenu",
   data() {
      return {
         custom_sports: JSON.parse(localStorage.getItem('sports_List')) ? JSON.parse(localStorage.getItem('sports_List')).custom : [],
         non_custom_sports: JSON.parse(localStorage.getItem('sports_List')) ? JSON.parse(localStorage.getItem('sports_List')).non_custom : [],
         eventMange: this.$store.getters.eventManage,
         sportId: null,
         leagueName: null,
         loading: false,
         todayMatchesList: null,
         favouriteSportsList: localStorage.getItem(varConstants.FAV_SPORTS_LIST) ? JSON.parse(localStorage.getItem(varConstants.FAV_SPORTS_LIST)) : [],
         searchLoading: false,
         searchResult: false,
         openIframeModal: false,
         searchString: null,
      }

   },
   components: {
      NoDataFound,
      OneClcikBet
   },
   created() {

   },
   computed: {
      siteSettings() {
         return this.$store.getters.siteSettings;
      },
      domainName() {
         return this.$store.getters.domainName;
      },
      checkIsLogin() {
         return this.$store?.getters?.isAuthenticated;
      },
   },
   mounted() {
      this.getEventSportsAllService('today');
      document.querySelector("#price").click(function () {
         document.querySelector("#btn1").toggleClass("d-none");
      });
   },
   methods: {
      checkInEventMange(type) {
         return this.eventMange ? this.eventMange?.filter(s => s.type == type).length != 0 ? false : true : true;
      },

      onChange(event) {
         this.searchString = event.target.value;
         this.searchCasinoMatches();
      },

      searchCasinoMatches() {
         console.log("TEst.. ")
         if (!this.searchLoading) {
            if (this.searchString != '' && this.searchString.length > 3) {
               try {
                  this.searchResult = null;
                  this.searchLoading = true;
                  let headers = {
                     'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                  }
                  let data = {
                     "search": this.searchString
                  };
                  api.post(apiName.SEARCH, data, { headers }).then(response => {
                     this.searchLoading = false;
                     if (response && response.status && response.status == 200) {
                        let resData = response.data.data ?? null;
                        console.log("Search result : ", resData);
                        this.searchResult = resData;


                     } else {
                        this.searchLoading = false;
                        this.showErrorModalFunc(response.data.debug[0]);
                     }
                  }).catch(error => {
                     this.searchLoading = false;
                     if (error) {
                        this.showErrorModalFunc(error[0]);
                     }
                  });
               } catch (error) {
                  console.log("Search Error : ", error);
               }
            }
            else {
               this.searchResult = null
            }
         } else {
            this.searchResult = null
         }
      },

      timeConverter(datetime) {
         return moment(datetime).format('D MMM  h:mm A');
      },

      getLeagues(sport) {
         this.loading = true;
         this.leagues_list = null;
         oddsApi.get(apiName.MATCHES_BY_SPORT_ID + sport.id).then(response => {
            this.loading = false;
            if (response) {
               if (response.status == 200) {
                  this.sportId = sport.id;
                  console.log("matches by id", response);
                  this.sportsData = response.data.data
                  this.sportsData.sort().reverse()
                  this.leagues_list = {}
                  this.sportsData?.forEach(item => {
                     const leagueName = item.league_name;
                     if (!this.leagues_list[leagueName]) {
                        this.leagues_list[leagueName] = [];
                     }
                     this.leagues_list[leagueName].push(item);
                  });

               }
            }
         }).catch(error => {
            this.loading = false;
            if (error) {
               console.log("Error : ", error);
               this.showErrorModalFunc(error.data.message);
            }
         });
      },
      getMatches(matches, leangeName) {
         console.log("Matches : ", matches);
         this.leagueName = leangeName;
         this.matchesList = matches
      },
      setTheme(theme) {
         document.documentElement.className = theme;
         localStorage.setItem("default_class", theme);
      },

      getEventSportsAllService(type) {
         this.loading = true;
         this.todayMatchesList = null;
         oddsApi.get(apiName.EVENTS_SPORTS_ALL + "?day=" + type).then(response => {
            this.loading = false;
            if (response && response.status == 200) {
               let matchesList = response.data.data
               this.todayMatchesList = matchesList;
               console.log("this.todayMatchesList : ", this.todayMatchesList);
            }
         }).catch(error => {
            this.loading = false;
            if (error) {
               console.log("Error : ", error);
            }
         });
      },
      dateTimeConverter(datetime) {
         return moment(datetime).format('HH:MM');
      },

      clickOnSportItem(match) {
         this.closeModal();
         this.$router.push('/sports-event-detail/' + match.event_id)
      },
      closeModal() {
         this.searchLoading=false,
         this.searchResult=null;
         this.searchString=null
         this.$refs.close_btn.click()
      },

   }

};
</script>

