
import { createRouter, createWebHistory } from "vue-router";
const Home = () => import("../views/Home.vue");
const Login = () => import("@/modules/authorization/components/Login.vue");
const SignUp = () => import("@/modules/authorization/components/SignUp.vue");
const ForgetPassword = () => import("@/modules/authorization/components/ForgetPassword.vue");

import WalletRoutes from "@/modules/wallet";
import BetsRoutes from "@/modules/bets";
import FavouriteRoutes from "@/modules/favourites";
import GamesRoutes from "@/modules/games";
import MyMarketRoutes from "@/modules/my-market";
import SportsRoutes from "@/modules/sports";
import VirtualSportRoutes from "@/modules/virtual-sport";
import SportBookRoutes from "@/modules/sports-book";
import MatkaRoutes from "@/modules/matka";
import NotificationsRoutes from "@/modules/notification";
import RecommendedRoutes from "@/modules/recommended";
import SearchRoutes from "@/modules/search";
import CricketFightRoutes from "@/modules/cricket-fight";
import RaceRoutes from "@/modules/race";
import BonusRoutes from "@/modules/bonus";
import ReferEarnRoutes from "@/modules/refer-earn";
import StaticRoutes from "@/modules/static";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: SignUp
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: ForgetPassword
  },
  WalletRoutes,
  BetsRoutes,
  FavouriteRoutes,
  GamesRoutes,
  MyMarketRoutes,
  SportsRoutes,
  VirtualSportRoutes,
  SportBookRoutes,
  MatkaRoutes,
  NotificationsRoutes,
  RecommendedRoutes,
  SearchRoutes,
  CricketFightRoutes,
  RaceRoutes,
  BonusRoutes,
  ReferEarnRoutes,
  StaticRoutes
];


export default createRouter({
  history: createWebHistory(),
  routes
});

