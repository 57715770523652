<template v-if="!checkIsLogin && captchaData">
    <div  class="input-field c-pass">
        <label class="active">Captcha</label>
        <input v-model="form.captchaText" type="tel" maxlength="4" aria-label="Username" aria-describedby="addon-wrapping">
        <span class="captcha-code" v-if="captchaData">
            <img :src="captchaData?.imageUri" alt="">
        </span>
    </div>
    <span v-if="showValidationError && form.captchaText == ''" class="text-danger">Please enter captcha</span>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Captcha',
    props: ['showValidationError', 'form'],
    computed: {
        ...mapGetters({
            captchaData: 'captchaData'
        }),

        checkIsLogin() {
			return this.$store.getters.isAuthenticated;
		},
    },
    beforeUnmount() {
        this.$store.dispatch('setCaptchaData', null);
    },
}
</script>

<style scoped>
.captcha-code {
    position: absolute;
    right: 0;
}

.captcha-code img {
    position: relative;
    bottom: 20px;
    right: 10px;
}
</style>