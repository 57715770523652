const RecommendedModule = () => import(/* webpackChunkName: "recommended-module" */ './views/Module.vue');
const Recommended = () => import(/* webpackChunkName: "recommended" */ './views/Recommended.vue');

const RecommendedRoutes = {
    path: '/',
    component: RecommendedModule,
    children: [
        {
            path: 'recommended',
            name: 'recommended',
            component: Recommended
        }
    ],
}

export default RecommendedRoutes;