
const Matka = () => import(/* webpackChunkName: "matka" */ './views/Matka.vue');
const MatkaModule = () => import(/* webpackChunkName: "matka-module" */ './views/Module.vue');
const MatkaDetails = () => import(/* webpackChunkName: "matka-details" */ './views/MatkaDetails.vue');
const MatkaMarket = () => import(/* webpackChunkName: "matka-market" */ './views/MatkaMarket.vue');

const MatkaRoutes =
{
    path: '/',
    component: MatkaModule,
    children: [
        {
            path: 'matka',
            name: 'matka',
            component: Matka,
        },
        {
            path: 'matka-details/:id',
            name: 'matka-details',
            component: MatkaDetails,
        },
        {
            path: 'matka-market',
            name: 'matka-market',
            component: MatkaMarket,
        }
    ],
}

export default MatkaRoutes;