<template>
           <!-- Modal -->
<div class="modal fade" id="one-click-bet" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div class="modal-dialog">
     <div class="modal-content">
       <div class="modal-header">
         <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
         <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
       </div>
       <div class="modal-body">
      
       </div>
       <div class="modal-footer">
         <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
         <button type="button" class="btn btn-primary">Save changes</button>
       </div>
     </div>
   </div>
 </div>

</template>
<script>
export default {
    name: "one-clcik-bet-Modal",
    props: ['message'],
    mounted() {
      // let data =   document.querySelector('#one-click-bet')
      // console.console.log(data);
  //  if($router.name === "one-clcik-bet-Modal"){
  //   alert()
  //  }
    }
}
</script>