<template>
    <div class="bonus-info">
        <div class="modal fade" id="informationModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true"
            role="dialog">
            <div class="modal-dialog modal-md modal-dialog modal-md -centered information-modal-wrapper">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="thm-heading">
                            <h3>Bonus information</h3>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="back-modal">
                            <div class="mene-details information-modal-sec">
                                <div class="menu-details-list">
                                    <ul>
                                        <li>
                                            <div class="menu-details-heading">
                                                <h4>Wallet Fun point</h4>
                                            </div>
                                            <div class="menu-details-heading">
                                                <h4><span>8000</span></h4>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="menu-details-heading">
                                                <h4>Exposure</h4>
                                            </div>
                                            <div class="menu-details-heading">
                                                <h4><span class="profit-color border-0">400.00</span></h4>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="menu-details-heading">
                                                <h4>Use Bonus</h4>
                                            </div>
                                            <div class="menu-heading-con">
                                                <div class="form-check form-switch m-0 p-0">
                                                    <input class="form-check-input" type="checkbox"
                                                        id="settingCheckDefaults" />
                                                </div>
                                            </div>
                                        </li>
                                        <li class="p-0">
                                            <div class="open-faq">
                                                <div class="accordion" id="accordionExample">
                                                    <div class="accordion-item mb-0 rounded-0">
                                                        <h2 class="accordion-header" id="headingOne">
                                                            <button class="accordion-button collapsed rounded-0"
                                                                type="button" data-bs-toggle="collapse"
                                                                data-bs-target="#teamOne" aria-expanded="true"
                                                                aria-controls="teamOne">
                                                                <div class="menu-details-heading">
                                                                    <h4>Bonus Wallet</h4>
                                                                </div>
                                                            </button>
                                                        </h2>
                                                        <div id="teamOne" class="accordion-collapse collapse"
                                                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div class="accordion-body team-sec">
                                                                <div class="mene-details information-modal-sec">
                                                                    <div class="menu-details-list">
                                                                        <ul>
                                                                            <li>
                                                                                <div class="menu-details-heading">
                                                                                    <h4>Wagering remaining</h4>
                                                                                </div>
                                                                                <div class="menu-details-heading">
                                                                                    <h4><span>0.00</span></h4>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="menu-details-heading">
                                                                                    <h4>Bonus Amount</h4>
                                                                                </div>
                                                                                <div class="menu-details-heading">
                                                                                    <h4><span>NaN</span></h4>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="menu-details-heading">
                                                                                    <h4>Bonus Issued</h4>
                                                                                </div>
                                                                                <div class="menu-details-heading">
                                                                                    <h4><span>04:30pm 25 jan 2023 </span>
                                                                                    </h4>
                                                                                </div>
                                                                            </li>
                                                                            <li>
                                                                                <div class="menu-details-heading">
                                                                                    <h4>Bonus Expiry</h4>
                                                                                </div>
                                                                                <div class="menu-details-heading">
                                                                                    <h4><span>04:30pm 30 jan 2023</span>
                                                                                    </h4>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="conditions-sec">
                            <a href="#" class="conditions-btn thm-but">Terms and Conditions</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WelcomeBonus'
}
</script>