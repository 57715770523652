<template>
    <div class="modal fade game-point-note-modal change-laguage-modal" id="language_selection_pop_up" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" style="border: none;">
                <div class="modal-body">
                    <section class="sub-header-wrapper">
                        <div class="gp-content">
                            <img data-bs-dismiss="modal" class="gp-closeButton" src="@/assets/images/closeButton.svg"
                                alt="">

                            <img data-bs-dismiss="modal" class="gp-closeButton" src="@/assets/images/closeButton.svg" alt=""
                                loading="lazy" />
                            <div class="swal2-icon swal2-warning swal2-icon-show">
                                <div class="swal2-icon-content"><img src="@/assets/images/language-icon.svg" alt=""
                                        loading="lazy" /></div>
                            </div>
                            <h2 class="swal2-title" id="swal2-title">Change Language</h2>

                            <div id="app_1"> </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>
<script>


export default {
    name: 'GamesPop1',
    methods: {
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    }
}
</script>