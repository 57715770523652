<template>
    <div class="footer-page">
        <span class="bottom-image"></span>
        <div class="footer-item">
            <ul>
                <li>
                    <router-link to="/" :class="{'active':$route.name=='Home'}">
                        <div class="list">
                            <div class="footer-img">
                                <img src="@/assets/images/home.svg" alt="" loading="lazy" />
                            </div>
                            <div class="footer-title-text">
                                <span>Home</span>
                            </div>
                        </div>
                    </router-link>
                </li>
                <li>
                    <router-link to="/sports/inplay/0" :class="{'active':$route.name=='sports'}">
                        <div class="list">
                            <div class="footer-img">
                                <img src="@/assets/images/sports.svg" alt="" loading="lazy" />
                            </div>
                            <div class="footer-title-text">
                                <span>Sports</span>
                            </div>
                        </div>
                    </router-link>
                </li>
                <li v-if="siteSettings?.social_link?.whatsappno1">
                    <a :href="'//wa.me/' + siteSettings.social_link.whatsappno1" target="_blank">
                        <div class="list bottom-tab">
                            <div class="footer-center-img">
                                <img src="@/assets/images/Whatsapp-img.svg" alt="" loading="lazy" />
                            </div>
                        </div>
                    </a>
                </li>
                <li>
                    <router-link to="/games/LIVE_CASINO" :class="{'active':$route.name=='games'}">
                        <div class="list">
                            <div class="footer-img">
                                <img src="@/assets/images/casino.svg" alt="" loading="lazy" />
                            </div>
                            <div class="footer-title-text">
                                <span>Casino</span>
                            </div>
                        </div>
                    </router-link>
                </li>
                <li>
                    <a  @click="clickOnAccountButton()" type="button">
                        <div class="list">
                            <div class="footer-img">
                                <img src="@/assets/images/profill-img.png" alt="" loading="lazy" />
                            </div>
                            <div class="footer-title-text">
                                <span>{{checkIsLogin()?userData?.userid:'Sign In'}}</span>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div ref="open_account_menu" style="display: none;" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample"
        aria-controls="offcanvasExample"></div>

    <div ref="open_login_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#login">
    </div>

</template>


<script>
import ChatComponent from '../chat/ChatComponent.vue';

export default {
    name: "Footer",
    emits: ['error-modal', 'success-modal'],
    components: {
        ChatComponent
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        userData(){
            return this.$store.getters.stateUser;
        }
    },
    mounted(){
        console.log("User Data : ",this.userData)
    },
    methods: {
        clickOnAccountButton() {
            if (this.checkIsLogin()) {
                this.$refs.open_account_menu.click();
            }
            else {
                this.$refs.open_login_modal.click();
            }
        },

        clickOnMyMarketButton() {
            if (this.checkIsLogin()) {
                this.$router.push({ name: 'my-market' });
            }
            else {
                this.$refs.open_login_modal.click();
            }
        },
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        }
    }
};
</script>

<style scoped>
.footer-list-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>