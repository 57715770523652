<template>
	<!-- header section -->
	<header v-if="checkForHeaderShow()" class="header-wrapper">
		<Header @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></Header>
	</header>

	<div v-if="onLine">
		<transition name="fade">
			<div class="preloader d-flex align-items-center justify-content-center" v-if="getGlobaLoader()">
				<div class="cssload-container">
					<div class="cssload-loading"><i></i><i></i><i></i><i></i></div>
				</div>
			</div>
		</transition>

		<NewsSection v-if="!newsSectionHide.includes($route.name)" />
		<router-view :key="$route.fullPath" @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
	</div>

	<div v-else>
		<NoInternetConnection />
	</div>

	<section class="contact-wrapper">
		<div class="container-fluid">
			<Contacts />
		</div>
	</section>


	<Copyrights />

	<!-- footer start -->
	<footer class="footer-sec">
		<Footer @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></Footer>
		<div class="back-to-top khelo-open-bet-wrapper wrapper-bottom bottom-10" :class="{ 'd-none': scY < 300 }"
			@click="goToTop()">
			<div class="container-fluid">
				<button id="myBtn" title="Go to top"><img src="@/assets/images/top-arrow.png" alt=""
						loading="lazy" /></button>
			</div>
		</div>
		<!-- <div class="khelo-open-bet-wrapper wrapper-bottom ">
            <div class="container-fluid">
                 <button onclick="goToTop()" id="myBtn" title="Go to top"><img src="./assets/images/top-arrow.png" alt="" loading="lazy" /></button>
             </div>
         </div> -->
	</footer>
	<!-- footer section end -->

	<!-- Login module -->
	<Login @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></Login>

	<!-- Sign up module -->
	<SignUp @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></SignUp>

	<!-- Forget password module -->
	<ForgetPassword @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></ForgetPassword>

	<div :class="{ show: showSuccessModal }" class="successfully-wrapper" style="z-index: 9999999999999999;"
		ref="successmodal" id="successfullyModal">
		<SuccessModal :message="successMsg" />
	</div>

	<div :class="{ show: showErrorModal }" style="z-index: 9999999999999999999999;" class="successfully-wrapper"
		id="wrongtoaster">
		<ErrorModal :message="errorMsg" />
	</div>

	<GamesPop1 @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
	<GamesPop2 @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />


	<LanguageSelection @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

	<Account v-if="checkIsLogin()" @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />


	<RulesModal @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

	<LockedBonus @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

	<Offers/>

	<div ref="click_logout_msg" @click="showErrorModalFunc('You are logout from this device.')" style="display: hidden;">
	</div>
</template>

<script>

import Header from "@/shared/components/header/Header.vue";
import Footer from "@/shared/components/footer/Footer.vue";
import Login from "@/modules/authorization/components/Login.vue";
import SignUp from "@/modules/authorization/components/SignUp.vue";
import ForgetPassword from "@/modules/authorization/components/ForgetPassword.vue";
import { AUTH_TOKEN } from "./shared/constants/cookies-const";
import axios from 'axios';
import NoInternetConnection from "./shared/components/no-internent/NoInternetConnection.vue";
import api from '@/shared/services/api';
import * as apiName from '@/shared/services/urls';
import { setHeaders } from '@/shared/services/headers';
import moment from 'moment';
import ErrorModal from "./shared/components/modal/ErrorModal.vue";
import SuccessModal from "./shared/components/modal/SuccessModal.vue";
import GamesPop1 from "./shared/components/modal/GamesPop1.vue";
import GamesPop2 from "./shared/components/modal/GamesPop2.vue";
import IFrameModal from "./modules/games/components/IFrameModal.vue";

import Translator from "vue-gtranslate";
import "vue-gtranslate/translator.css"

import LanguageSelection from "./shared/components/header/LanguageSelection.vue";
import Account from "./modules/account/components/Account.vue";
import { callFavouriteApis } from '@/shared/services/common-services';
import RulesModal from "./modules/account/components/RulesModal.vue";
import LockedBonus from "./shared/components/header/LockedBonus.vue";
import { mapGetters } from 'vuex';
import socketService from "./socket/sport-socket-service";
import Contacts from "./shared/components/footer/Contacts.vue";
import Copyrights from "./shared/components/footer/Copyrights.vue";
import NewsSection from "./shared/components/modal/NewsSection.vue";
import captchaApi from "./modules/authorization/services/captcha-api";
import Offers from "./shared/components/header/Offers.vue";
import { Modal } from 'bootstrap';

export default {
	name: "App",
	data() {
		return {
			onLine: navigator.onLine,
			showBackOnline: false,
			showSuccessModal: false,
			successMsg: "",
			showErrorModal: false,
			errorMsg: "",
			refreshInit: false,
			scY: 0,
			scTimer: 0,
			offerModal : '',
			newsSectionHide: ['Home', 'match-market', 'wallet-history', 'wallet-history-detail', 'notifications', 'racing-list-details', 'racing-category', 'racing-carousel']
		}
	},
	computed: {
		...mapGetters({
			captchaData: 'captchaData', siteSettings: 'siteSettings', eventDetailHideSections: 'eventDetailHideSections'
		}),
		routePath() {
			return this.$route.name
		}
	},
	mounted() {
		//for use on js pages
		window.store = this.$store;
		window.cookies = this.$cookies;
		window.router = this.$router;
		this.offerModal = new Modal("#offer_pop_up");
		window.emitLogoutMsg = this.$refs.click_logout_msg
		window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
		//for add on all APIs
		axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
		setHeaders();
		if (this.checkIsLogin()) {
			callFavouriteApis();
		}

		if (!this.checkIsLogin()) {
			captchaApi.getCaptchaDataServiceCall();
		}
		//checking internet connection
		window.addEventListener('online', this.updateOnlineStatus);
		window.addEventListener('offline', this.updateOnlineStatus);
		if (localStorage.getItem('default_class')) {
			document.documentElement.className = localStorage.getItem('default_class')
		}
		else {
			//set default theme
			this.setTheme('dark')
		}
		window.addEventListener('scroll', this.onScroll);
	},
	computed: {
		siteSettings() {
			return this.$store.getters.siteSettings;
		},
		domainName() {
			return this.$store.getters.domainName;
		}
	},
	created() {
		this.getSiteSettingsServiceCall();

		this.socketAllEvents();
	},
	beforeDestroy() {
		window.removeEventListener('online', this.updateOnlineStatus);
		window.removeEventListener('offline', this.updateOnlineStatus);
	},
	components: {
		Header,
		Footer,
		Login,
		ForgetPassword,
		SignUp,
		NoInternetConnection,
		ErrorModal,
		SuccessModal,
		GamesPop1,
		GamesPop2,
		IFrameModal,
		Translator,
		LanguageSelection,
		Account,
		RulesModal,
		LockedBonus,
		Contacts,
		Copyrights,
		NewsSection,
		Offers
	},
	methods: {
		setTheme(theme) {
			localStorage.setItem('default_class', theme)
			document.documentElement.className = theme;
		},
		getGlobaLoader() {
			return this.$store.getters.globalLoader;
		},
		getSiteSettingsServiceCall() {
			this.$store.dispatch('setGlobalLoader', true);
			api.get(apiName.SITE_SETTINGS + "?domain=" + window.location.hostname).then(response => {
				this.$store.dispatch('setGlobalLoader', false);
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.showErrorModalFunc(response.data.debug[0]);
					} else {
						let resData = response?.data?.data
						this.$store.dispatch('setSettings', resData);
						this.setManifest(resData);

						const favElement = document.createElement('link');
						favElement.rel = 'icon';
						favElement.href = resData.aws_url + resData.storage_path.domain_image + resData.fav_logo;
						document.head.insertAdjacentElement('beforeend', favElement);

						const title = document.getElementById("title");
						title.text = resData.domain_name;

						let is_offer = localStorage.getItem("is_offer");

						if (this.checkIsLogin()){
							this.getWalletBalance();
						}
						if(this.checkIsLogin() && resData.offer != null && is_offer == null){
							this.offerModal.show();
						}
					}
				}
			}).catch(error => {
				this.$store.dispatch('setGlobalLoader', false);
				if (error) {
					this.showErrorModalFunc(error[0]);
				}
			});
		},
		setManifest(resData) {
			let manifestName = resData.domain_name.charAt(0).toUpperCase() + resData.domain_name.slice(1).toLowerCase();
			var dynamicManifest = {
				name: manifestName,
				short_name: manifestName,
				start_url: window.location.origin,
				display: 'standalone',
				icons: [{
					src: resData.app_logo ? resData.aws_url + resData.storage_path.domain_image + resData.app_logo : resData.aws_url + resData.storage_path.domain_image + resData.fav_logo,
					sizes: "256x256 512x512 192x192 144x144",
					type: "image/png",
					purpose: 'any'
				}]
			}
			
			// Manifest Blob
			const stringManifest = JSON.stringify(dynamicManifest);
			const blob = new Blob([stringManifest], { type: 'application/json' });
			const manifestURL = URL.createObjectURL(blob);
			
			// Manifest Creation
			const favElement = document.createElement('link');
			favElement.rel = 'manifest';
			favElement.href = manifestURL;
			document.head.insertAdjacentElement('beforeend', favElement);
			
			// IOS APK Logo
			let logo = resData.aws_url + resData.storage_path.domain_image + resData.logo;
			document.querySelector('#appleIcon').setAttribute('href', logo);
			document.querySelector('#appleLaunchIcon').setAttribute('href', logo);

			// IOS App title
			const metaTag = document.createElement('meta');
			metaTag.setAttribute('name', 'apple-mobile-web-app-title');
			metaTag.setAttribute('content', manifestName);
			document.head.insertAdjacentElement('beforeend', metaTag);

		},
		getWalletBalance() {
			let authToken = this.$cookies.get(AUTH_TOKEN);
			if (!authToken) return;
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
			api.post(apiName.WALLET_BALANCE, {}, { headers }).then(response => {
				if (response && response.status == 200 && response.data) {
					let user = this.$store.getters.stateUser;
					user.balance = response.data.bl;
					user.exposure = response.data.ex;
					user.bets_count = response.data.bc;
					this.$store.dispatch('setUser', user);
					if ((user.exp - moment().utc().unix()) < 10) {
						this.refreshAuthToken(headers);
					} else {
						setTimeout(function () {
							this.getWalletBalance();
						}.bind(this), 2000);
					}
				} else {
					this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
					this.$store.dispatch('resetAllState');
				}
			}).catch(error => {
				if (error) {
					this.showErrorModalFunc(error[0]);
				}
			});
		},
		refreshAuthToken(headers) {
			this.refreshInit = true;
			api.post(apiName.AUTH_REFRESH, {}, { headers }).then(response => {
				let user = this.$store.getters.stateUser;
				user.token = response.data.data.token;
				user.exp = response.data.data.exp;
				this.$cookies.set(AUTH_TOKEN, response.data.data.token);
				this.$store.dispatch('setToken', `Bearer ${response.data.data.token}`);
				window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
				//for add on all APIs
				axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
				this.$store.dispatch('setUser', user);
				this.refreshInit = false;
				setTimeout(function () {
					this.getWalletBalance();
				}.bind(this), 2000);
			}).catch(error => {
				if (error) {
					this.showErrorModalFunc(error[0]);
				}
				this.refreshInit = false;
			});
		},
		checkForHeaderShow() {
			if (this.routePath == 'search') {
				return false;
			} else if (this.eventDetailHideSections && this.eventDetailHideSections.includes('show_full_screen') && this.routePath == 'sports-event-detail') {
				return false;
			} else {
				return true;
			}
		},
		updateOnlineStatus(e) {
			const {
				type
			} = e;
			this.onLine = type === 'online';
		},
		checkIsLogin() {
			return this.$store.getters.isAuthenticated;
		},
		showErrorModalFunc(message) {
			if (message && message != "") {
				this.errorMsg = message;
				this.showErrorModal = true;
				setTimeout(() => {
					this.showErrorModal = false;
				}, 2000);
			}
		},
		showSuccessModalFunc(message) {
			if (message && message != "") {
				this.successMsg = message;
				this.showSuccessModal = true;
				setTimeout(() => {
					this.showSuccessModal = false;
				}, 2000);
			}
		},
		goToTop() {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		},
		onScroll() {
			if (this.scTimer) return;
			this.scTimer = setTimeout(() => {
				this.scY = window.scrollY;
				clearTimeout(this.scTimer);
				this.scTimer = 0;
			}, 100);
		},
		socketAllEvents() {
			if (this.$store.getters.stateUser) {
				socketService.emit("sub", 'depositRequest-' + this.$store.getters.stateUser.userid);
				socketService.emit("sub", 'logout-' + this.$store.getters.stateUser.userid);
				socketService.emit("sub", 'banned-' + this.$store.getters.stateUser.userid);

				socketService.on("DepositRequest", (data) => {
					if (data.type == "myId") {
						if (data.notify_type == 1) {
							this.$store.dispatch('setNotification');
							this.showSuccessModalFunc('New Notification...')
						}
					}
				});
				socketService.on("logout", (data) => {
					if (data.type == "logout") {
						// logout user from users
						// if (data.notify_type == 1) {
						// 	this.$store.dispatch('setNotification');
						// 	this.showSuccessModalFunc('New Notification...')
						// }
					}
				});
				socketService.on("banned", (data) => {
					if (data.type == "match_ban") {
						// match ban condition
					} else if (data.type == 'league_ban') {
						// league ban condition
					}
				});

			}
		}
	},
	watch: {
		onLine(v) {
			if (v) {
				this.showBackOnline = true;
				setTimeout(() => {
					this.showBackOnline = false;
				}, 1000);
			}
		}
	}
};



</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 1.5s
}

.fade-enter,
.fade-leave-to {
	opacity: 0
}

.bottom-10 {
	bottom: 10px !important;
}
</style>

