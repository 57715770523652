<template>
    <div class="contact-item contacts-sec">
        <div class="contact-sub-item">
            <div class="row g-2">
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="footer-contant">
                        <div v-if="siteSettings" class="footer-logo-sec">
                            <div class="footer-logo">
                                <a class href="index.html"><img class="logo"
                                        :src="siteSettings.aws_url + siteSettings.storage_path.domain_image + siteSettings.logo"
                                        alt="logo" loading="lazy" /></a>
                            </div>
                            <div class="sub-title">
                                <p>{{ domainName }} is Asia’s one of the biggest gaming platforms with over 350 games and
                                    sports available.</p>
                                <p>
                                    Popular sports such as Cricket, Tennis, Football, Basketball, Volleyball and many more
                                    including Indian card games like Teen Patti and Andar Bahar are also available from
                                    various worldwide gaming
                                    providers.
                                </p>
                                <p>With unlimited markets and odds, kheloyar.site gives players the best gaming experience
                                    in the market.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="safe-payment">
                        <div class="pay-details">
                            <h4>100% Safe & instant payments</h4>
                        </div>
                        <div class="pay-text">
                            <p>You can make payments and receive earnings instantly via your UPI ID - so you can be sure
                                that your money is safe and secure. All UPI platforms are accepted.</p>
                        </div>
                        <div class="pay-icn">
                            <ul>
                                <li>
                                    <div class="pay-img">
                                        <img src="@/assets/images/18plus.png" alt="18plus" loading="lazy" />
                                    </div>
                                </li>
                                <li>
                                    <div class="pay-img">
                                        <img src="@/assets/images/gamecare.png" alt="gamecare" loading="lazy" />
                                    </div>
                                </li>
                                <li>
                                    <div class="pay-img">
                                        <img src="@/assets/images/gt.png" alt="gt" loading="lazy" />
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="support-sec">
                            <div class="support-but">
                                <a>24/7 customer support</a>
                            </div>
                            <div class="support-item">
                                <div class="support-img">
                                    <img src="@/assets/images/user-support.png" alt="user-support" loading="lazy" />
                                </div>
                                <div v-if="siteSettings?.social_link?.whatsappno1" class="support-text">
                                    <p>Hindi: <a :href="'//wa.me/' + siteSettings.social_link.whatsappno1" target="_blank" >Click Here</a></p>
                                    <p>English: <a :href="'//wa.me/' + siteSettings.social_link.whatsappno1" target="_blank">Click Here</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4">
                    <div class="payment-details-sec">
                        <div v-if="siteSettings?.business_type == 2" class="pay-details">
                            <h4>accepted modes of payments</h4>
                        </div>
                        <div v-if="siteSettings?.business_type == 2" class="payment-details-item">
                            <ul>
                                <li @click="clickOnWalletDeposit()">
                                    <div class="pay-details-img">
                                        <img src="@/assets/images/upl.png" alt="" loading="lazy" />
                                    </div>
                                </li>
                                <li @click="clickOnWalletDeposit()">
                                    <div class="pay-details-img phonepay-img">
                                        <img src="@/assets/images/phonepay.png" alt="" loading="lazy" />
                                    </div>
                                </li>
                                <li @click="clickOnWalletDeposit()">
                                    <div class="pay-details-img">
                                        <img src="@/assets/images/GooglePay.png" alt="" loading="lazy" />
                                    </div>
                                </li>
                                <li @click="clickOnWalletDeposit()">
                                    <div class="pay-details-img">
                                        <img src="@/assets/images/bank-transfor.png" alt="" loading="lazy" />
                                    </div>
                                </li>
                                <li @click="clickOnWalletDeposit()">
                                    <div class="pay-details-img">
                                        <img src="@/assets/images/Paytm.png" alt="" loading="lazy" />
                                    </div>
                                </li>
                                <li @click="clickOnWalletDeposit()">
                                    <div class="pay-details-img">
                                        <img src="@/assets/images/MasterCard.png" alt="" loading="lazy" />
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div v-if="siteSettings?.social_link" class="con-no-1">
                            <div class="pay-details-content">
                                <h4>Contact Us</h4>
                            </div>
                            <div class="pay-details-text">
                                <p>
                                    WhatsApp : <span id="phone-mumber-1" v-if="siteSettings?.social_link?.whatsappno1">
                                        <a :href="'//wa.me/' + siteSettings.social_link.whatsappno1" target="_blank">
                                        {{ siteSettings?.social_link?.whatsappno1 }}</a></span>
                                    <br />
                                    Calling : <span id="phone-mumber-2" v-if="siteSettings?.social_link?.supportno1">
                                        <a :href="'//wa.me/' + siteSettings?.social_link?.supportno1" target="_blank">
                                        {{ siteSettings?.social_link?.supportno1 }}
                                    </a>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-social-icon">
            <div v-if="siteSettings && siteSettings?.social_link" class="social-items">
                <ul>
                    <li  v-if="siteSettings?.social_link?.instagram_link">
						<a :href="siteSettings.social_link.instagram_link" target="_blank">
                            <span> <img src="@/assets/images/Instagram.png" alt="" loading="lazy" /></span> Instagram
                        </a>
                    </li>
                    <li v-if="siteSettings.social_link.facebook_link">
						<a :href="siteSettings.social_link.facebook_link" target="_blank">
                            <span><img src="@/assets/images/Facebook.png" alt="" loading="lazy" /></span> Facebook
                        </a>
                    </li>
                    <li>
                        <a target="_blank">
                            <span><img src="@/assets/images/Twitter.png" alt="" loading="lazy" /></span> Twitter
                        </a>
                    </li>
                    <li v-if="siteSettings?.social_link?.telegram_link">
						<a :href="siteSettings.social_link.telegram_link" target="_blank">
                            <span> <img src="@/assets/images/Telegram.png" alt="" loading="lazy" /></span> Telegram
                        </a>
                    </li>
                    <li v-if="siteSettings?.social_link?.whatsappno1">
                        <a :href="'//wa.me/' + siteSettings.social_link.whatsappno1" target="_blank">
                            <span> <img src="@/assets/images/Whatsapp.png" alt="" loading="lazy" /></span> Whatsapp
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="about-us-right">
            <div class="about-us-list">
                <div class="about-us-text">
                    <ul>
                        <li>
                            <router-link :to="{ name: 'static', params: { type: types().ABOUT_US } }" class="texts">
                                <span> About Us </span>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'static', params: { type: types().KYC_POLICY } }" class="texts">
                                <span> KYC Policy </span>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'static', params: { type: types().TERM_CONDITIONS } }" class="texts">
                                <span> Terms & Condition </span>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'static', params: { type: types().RESPONSIBLE_GAMING } }" class="texts">
                                <span> Responsible Gambling </span>
                            </router-link>
                        </li>
                        <li>
                            <router-link :to="{ name: 'static', params: { type: types().COOKIE_POLICY } }" class="texts">
                                <span> Cookie Policy </span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as types from '@/modules/static/utils/constants';

export default {
    name: 'Contacts',
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        domainName() {
            return this.$store.getters.domainName;
        }
    },
    methods:{
        types(){
            return types;
        },
        
		clickOnWalletDeposit() {
            this.$router.push({ path: '/wallet-deposit', query: { walletData: null } })
        },
    }
}
</script>